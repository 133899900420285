import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { ConnectedTextInput } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';
import { useSetRecoilState } from 'recoil';

import { appointmentsApi } from '~modules/api';
import { appointmentUtilities, useVisitReasons } from '~modules/appointment';
import { MainForm } from '~modules/forms';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { historyAtom } from '~modules/state';

import { useSaveHistory } from '../../hooks';

import type { FormValues } from '@almond/ui';
import type { ALLERGIES_PAGE_NAME, HistoryState } from '~types';

export const AllergiesPage: React.FC = () => {
  const { t } = useTranslation();
  const setHistoryState = useSetRecoilState(historyAtom);
  const { doAsync, isLoading } = useAsync();
  const saveHistory = useSaveHistory();
  const { dispatch } = useRouteNavigation<typeof ALLERGIES_PAGE_NAME>();
  const searchParams = useLocalSearchParams();
  const { visitReasons, isLoadingVisitReasons } = useVisitReasons();

  const handleSubmit = useCallback(
    (formValues: FormValues<HistoryState> | null) => {
      if (!formValues) {
        setHistoryState(prevState => ({ ...prevState, allergies: undefined }));
      }

      const toCall = async (): Promise<void> => {
        await saveHistory(formValues, 'allergies', 'allergies', t('allergies.skipTitle'));

        if (searchParams.appointment_uuid) {
          if (!visitReasons) return;

          const appointment = await appointmentsApi.getAppointment(searchParams.appointment_uuid);
          const isObAppointment = appointmentUtilities.isMaternityVisitReason(
            visitReasons,
            appointment.visitReasons?.[0]
          );

          return dispatch('submit', { isObAppointment });
        }

        await dispatch('submit');
      };

      doAsync(toCall);
    },
    [dispatch, doAsync, saveHistory, searchParams.appointment_uuid, setHistoryState, t, visitReasons]
  );

  return (
    <MainForm
      id="allergies"
      title={t('allergies.title')}
      submitButtonTitle={t('continue')}
      onSubmit={handleSubmit}
      secondaryButtonTitle={t('allergies.skipTitle')}
      onSecondaryPress={() => handleSubmit(null)}
      atom={historyAtom}
      requiredFields={['allergies']}
      isLoading={isLoading}
      isDataLoading={isLoadingVisitReasons}
    >
      <ConnectedTextInput name="allergies" multiline placeholder={t('allergies.placeholder')} testID="Allergies" />
    </MainForm>
  );
};
