import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(() => ({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: 12,
  },
}));
