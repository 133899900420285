import { useCallback } from 'react';

import { useSetAppState } from '~modules/state';

import { log } from '../logger';
import { useProcessNavigationState } from './useProcessNavigationState';

import type { AppState } from '~types';

export const useRedirectCallback = () => {
  const setAppState = useSetAppState();
  const processNavigationState = useProcessNavigationState();

  return useCallback(
    async (appState?: AppState, preventNavigation?: boolean) => {
      if (!appState) return;

      log('Redirect callback has been called and the app state includes the next keys:', Object.keys(appState));
      await setAppState(appState);
      await processNavigationState(appState, preventNavigation || false);
    },
    [processNavigationState, setAppState]
  );
};
