import { useGlobalSearchParams } from 'expo-router';
import useSWRImmutable from 'swr/immutable';
import { z } from 'zod';

import { PRODUCTS_PATH, productsApi } from '~modules/api';

import type { ProductKeyEnum } from '@almond/api-types';

// Handle invalid product key values
const productKeySchema = z.enum(['gynecology_membership', 'baseline', 'virtual_membership']).optional();

export const useStripeProduct = () => {
  // Since this hook is used in Stripe Elements component, must be global not local search params
  const { product } = useGlobalSearchParams();

  const { data, isLoading, ...restResponse } = useSWRImmutable(PRODUCTS_PATH, productsApi.getProducts);

  const productKey = (productKeySchema.safeParse(product).data ?? 'gynecology_membership') satisfies ProductKeyEnum;

  return {
    product: productKey ? data?.[productKey] : undefined,
    isLoading: isLoading || !productKey,
    ...restResponse,
  };
};
