import { useCallback } from 'react';

import { useRecoilValue } from 'recoil';

import { profilesApi } from '~modules/api';
import { useStripeMembership } from '~modules/product';
import { profileAtom } from '~modules/state';

import { logError } from '../logger';

export const useUpsertStripeCustomer = () => {
  const profileState = useRecoilValue(profileAtom);
  const membershipType = useStripeMembership();

  return useCallback(async () => {
    if (!profileState.uuid) {
      logError('profile uuid is not available - cannot getOrCreate Stripe customer');

      return;
    }

    await profilesApi.upsertStripeCustomer(profileState.uuid)({
      membershipType: membershipType ?? 'gynecology_membership',
    });
  }, [profileState.uuid, membershipType]);
};
