import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';

import { useAsync } from '~modules/promises';
import { patientAtom, profileAtom, userAtom } from '~modules/state';

export const useNavigationBar = () => {
  const userState = useRecoilValue(userAtom);
  const { isAdmin } = userState;
  const { isLoading } = useAsync();
  const profileState = useRecoilValue(profileAtom);
  const { patientUuid, isNewMember } = useRecoilValue(patientAtom);

  const bannerText = (() => {
    if (isAdmin && isNewMember) {
      return 'New Member Booking';
    }

    if (!patientUuid || !isAdmin) {
      return 'almond';
    }

    if (isLoading || !profileState.firstName || !profileState.lastName) {
      return '';
    }

    const formattedDateString = dayjs(profileState.birthday, 'YYYY-MM-DD').format('MM/DD/YYYY');

    let preferredName = ' ';

    if (profileState.preferredFirstName) {
      preferredName = ` "${profileState.preferredFirstName}" `;
    }

    return `${profileState.firstName}${preferredName}${profileState.lastName} (${formattedDateString})`;
  })();

  return { isLoading: isAdmin ? isLoading : false, bannerText };
};
