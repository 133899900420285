import { type PropsWithChildren, useEffect } from 'react';

import { useRecoilCallback } from 'recoil';

import { useRestoreStateOnBack } from '~modules/auth';

import { useProcessInitialUrl, useProcessReferrals } from '../../hooks';

import type { BaseParamsWithoutRouting, RoutingConfig, StackParamList } from '~types';
import type { RecoilValue } from 'recoil';

type GetState = <T>(recoilValue: RecoilValue<T>) => Promise<T>;

export type RouteBehaviorProps =
  | { allowDirectRouting?: false; routingConfig?: never; forceSearchParams?: never }
  | {
      allowDirectRouting: true;
      /**
       * The routing config to attach if directly navigated to, or a function that returns
       * the routing config to navigate to based on the URL search params.
       *
       * Refreshing a page will only keep it on the same routing config from pre-reload if
       * that routing config is passed here or returned when called with searchParams. Otherwise,
       * it will act as though the routingConfig wasn't specified at all and redirect to the
       * beginning of the flow.
       */
      routingConfig:
        | RoutingConfig<StackParamList>
        | ((getState: GetState, searchParams: URLSearchParams) => Promise<RoutingConfig<StackParamList> | null>);

      forceSearchParams?: BaseParamsWithoutRouting;
    };

export const RouteBehavior = (props: PropsWithChildren<RouteBehaviorProps & { alreadyInitted: boolean }>) => {
  const processInitialUrl = useProcessInitialUrl();
  const restoreStateOnBack = useRestoreStateOnBack();
  const processReferrals = useProcessReferrals();

  useEffect(() => {
    if (!props.alreadyInitted) {
      processReferrals();
    }
  }, [processReferrals, props.alreadyInitted]);

  const { routingConfig } = props;
  const getRoutingConfig = useRecoilCallback(
    recoilInterface => {
      const parsedUrl = new URL(window.location.href);

      return async () => {
        return typeof routingConfig === 'function'
          ? routingConfig(recoilInterface.snapshot.getPromise.bind(recoilInterface.snapshot), parsedUrl.searchParams)
          : routingConfig || null;
      };
    },
    [routingConfig]
  );

  useEffect(() => {
    if (props.alreadyInitted) {
      return;
    }

    const stateRestoredOnBack = restoreStateOnBack();

    if (stateRestoredOnBack) {
      return;
    }

    getRoutingConfig().then(calculatedRoutingConfig => {
      processInitialUrl(props.allowDirectRouting ?? false, calculatedRoutingConfig, props.forceSearchParams);
    });
  }, [
    processInitialUrl,
    props.allowDirectRouting,
    props.alreadyInitted,
    getRoutingConfig,
    props.forceSearchParams,
    restoreStateOnBack,
  ]);

  return props.children;
};
