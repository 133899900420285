import type { PromotionCodeOut } from '@almond/api-types';

// TODO: Add to the backend, probably with some better names :)
export type PerniciousHabitEnum = 'daily' | 'weekly' | 'monthly' | 'yearly' | 'never' | 'other';

// Errors
export type Error = {
  error_code: string;
  message: string;
};

export type ErrorResponse = { errors: Error[] };

export type PromotionCodeInitial = {
  code: string;
  state: 'init';
};
export type PromotionCodeNotFound = {
  code: string;
  state: 'not-found';
};
export type PromotionCodeNotApplicable = {
  code: string;
  state: 'not-applicable'; // code does not apply to product
};
export type PromotionCodeReturning = {
  code: undefined;
  state: 'returning'; // returning member, can't use promo code
};
export type PromotionCodeResult = {
  code: string;
  state: 'success';
} & PromotionCodeOut;

export type PromotionCode =
  | PromotionCodeInitial
  | PromotionCodeNotFound
  | PromotionCodeNotApplicable
  | PromotionCodeResult
  | PromotionCodeReturning;
