import React, { useCallback, useMemo } from 'react';

import { useTranslation } from '@almond/localization';
import { Label } from '@almond/ui';
import { formatPriceInCents, parseStrikethrough } from '@almond/utils';
import { useRecoilValue } from 'recoil';

import { PAYMENT_FAILED_ERROR_STATUS_CODE, PaymentRequiredError } from '~modules/errors';
import { MainForm } from '~modules/forms';
import { useCalculatePrice, useCreateInvoice } from '~modules/payment';
import { useStripeProduct } from '~modules/product';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { creditCardAtom, patientProgressAtom, userAtom } from '~modules/state';
import { MessageContainer } from '~modules/ui';

import { useCreateAccount } from '../../hooks';
import { logError } from '../../logger';

import type { CONFIRM_PURCHASE_PAGE_NAME } from '~types';

export const ConfirmPurchasePage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync: doAsyncPurchase, isLoading: isPurchasing } = useAsync();
  const userState = useRecoilValue(userAtom);
  const creditCardState = useRecoilValue(creditCardAtom);
  const { isAdmin } = userState;
  const { dispatch } = useRouteNavigation<typeof CONFIRM_PURCHASE_PAGE_NAME>();
  const { product } = useStripeProduct();
  const createInvoice = useCreateInvoice();
  const createAccount = useCreateAccount();
  const { fullPrice, discountPrice, isLoading } = useCalculatePrice(product, creditCardState);
  const patientProgressState = useRecoilValue(patientProgressAtom);

  const priceContent = useMemo(() => {
    if (discountPrice === fullPrice) {
      return formatPriceInCents(discountPrice);
    }

    return parseStrikethrough(`~${formatPriceInCents(fullPrice)}~ ${formatPriceInCents(discountPrice)}`);
  }, [fullPrice, discountPrice]);

  const handleSubmit = useCallback(async () => {
    const toCall = async () => {
      let invoice: string | null;

      if (patientProgressState.invoice) {
        await createAccount();

        return;
      }

      try {
        invoice = await createInvoice();
      } catch (error: any) {
        if (error instanceof PaymentRequiredError && error.status === PAYMENT_FAILED_ERROR_STATUS_CODE) {
          return dispatch('cardRequired', error.message);
        }

        throw error;
      }

      if (!invoice && !isAdmin) {
        logError(
          new Error(`Invoice wasn't purchased for patient user.`),
          JSON.stringify({ userId: userState.user?.authId })
        );
      }

      await createAccount();
    };

    doAsyncPurchase(toCall);
  }, [
    doAsyncPurchase,
    patientProgressState.invoice,
    isAdmin,
    createAccount,
    createInvoice,
    dispatch,
    userState.user?.authId,
  ]);

  return (
    <MainForm
      id="confirmPurchase"
      title={t('confirmPurchase.title')}
      submitButtonTitle={t('confirmPurchase.submitTitle')}
      onSubmit={handleSubmit}
      isLoading={isPurchasing}
      isDataLoading={isLoading}
      submitButtonTestID="ConfirmPurchase"
      submitButtonType="accent"
    >
      {product && (
        <MessageContainer>
          <Label title={t('confirmPurchase.serviceName')}>{product?.name}</Label>
          <Label title={t('confirmPurchase.payment')} isLast>
            {priceContent}
          </Label>
        </MessageContainer>
      )}
    </MainForm>
  );
};
