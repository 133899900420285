import React, { useEffect } from 'react';

import { ActivityIndicator } from '@almond/ui';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocalSearchParams, useRouter } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { usersApi } from '~modules/api';
import { AUTH_STATES, useAuthState } from '~modules/auth';
import { getArtemisRedirectUrl } from '~modules/integration';
import { useAsync } from '~modules/promises';
import { getAllowedInitialSearchParams, useRouteNavigation } from '~modules/routing';
import { userAtom } from '~modules/state';
import { Background } from '~modules/ui';

import { log } from '../../logger';

import type { PATIENT_LOGIN_PAGE_NAME } from '~types';

export const PatientLoginPage: React.FC = () => {
  const { doAsync } = useAsync();
  const { dispatch } = useRouteNavigation<typeof PATIENT_LOGIN_PAGE_NAME>();
  const searchParams = useLocalSearchParams();
  const { setParams } = useRouter();
  const { isLoading: isAuth0Loading, isAuthenticated } = useAuth0();

  const authState = useAuthState();
  const userState = useRecoilValue(userAtom);

  useEffect(
    () => {
      if (isAuth0Loading || !isAuthenticated) return;

      if (authState === AUTH_STATES.loading) return;

      const toCall = async (): Promise<void> => {
        // If `hasRedirect` is specified then it means that the user has just set a new password,
        // so we need to consume the redirect and process the url params.
        if (!userState.user?.hasRedirect) {
          log("The current user doesn't have any redirects, so can't consume it.");

          const artemisRedirectUrl = getArtemisRedirectUrl(searchParams.artemis_redirect_url);

          // If user resets their password, but not as part of the Careguide-booked post-booking flow
          // (i.e. trying to log into Artemis), there won't be a redirect to consume. In that case,
          // bring the user to Artemis (because that's where they were trying to get)
          window.location.replace(artemisRedirectUrl);

          return;
        }

        // Question - do we actually need the redirect value? Or is the boolean enough? Can we "consume" it (delete it)
        // here, but ignore the response?
        // Answer - after talking with Tanay, looks like we need the product ID, and possibly the
        // booking/appointment IDs
        const response = await usersApi.consumeRedirect();
        const { searchParams: urlSearchParams } = new URL(response.url);
        const initialSearchParams = getAllowedInitialSearchParams(urlSearchParams);

        await dispatch('signin', undefined, initialSearchParams);
      };

      doAsync(toCall);
    },
    // Ignoring `searchParams.artemis_redirect_url`, otherwise the redirect will be consumed twice.
    // `searchParams.artemis_redirect_url` shouldn't be changed, because it comes from the outside.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [doAsync, setParams]
  );

  return (
    <Background>
      <ActivityIndicator />
    </Background>
  );
};
