import { SchedulingPage } from '~modules/appointment';
import { withRouteBehavior } from '~modules/navigation';
import { configurations } from '~modules/routing';
import { patientAtom } from '~modules/state';

export default withRouteBehavior(SchedulingPage, {
  allowDirectRouting: true,
  routingConfig: async (getState, searchParams) => {
    const existingRoutingConfigId = searchParams.get('routing_config_id');

    if (
      existingRoutingConfigId &&
      existingRoutingConfigId !== 'initialNewMember' &&
      existingRoutingConfigId !== 'initial'
    ) {
      return null;
    }

    // TODO detect invalid visit_reason and return null if invalid.
    // For now, doing this check inside useVisitReasons()
    const { isNewMember } = await getState(patientAtom);

    if (isNewMember) {
      return configurations.initialNewMember;
    }

    return configurations.initial;
  },
  title: 'Scheduling',
});
