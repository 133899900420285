import { useEvent } from '@almond/utils';
import { useLocalSearchParams, useRouter } from 'expo-router';
import { useSetRecoilState } from 'recoil';

import { isBaselineProduct } from '~modules/product';
import { patientAtom, patientProgressAtom } from '~modules/state';

import type { PatientDetailOut, SubscriptionOut } from '@almond/api-types';

export const useProcessPatientResponse = (): ((
  response: PatientDetailOut,
  subscription: SubscriptionOut | null
) => void) => {
  const setPatientState = useSetRecoilState(patientAtom);
  const setPatientProgressState = useSetRecoilState(patientProgressAtom);
  const { setParams } = useRouter();
  const searchParams = useLocalSearchParams();

  return useEvent((response: PatientDetailOut, subscription: SubscriptionOut | null) => {
    setPatientState(prevState => ({
      ...prevState,
      patientUuid: response.uuid,
      lastSeenTimestamp: response.lastSeenTimestamp,
      loginsCount: response.loginsCount,
    }));

    const subscriptionActive = subscription?.status ? ['active', 'trialing'].includes(subscription.status) : false;

    setPatientProgressState(prevState => ({
      ...prevState,
      emailVerified: response.emailVerified,
      consent: response.consent,
      lab: response.lab,
      pharmacy: response.pharmacy,
      referral: response.referral,
      historyData: response.historyData,
      address: response.profile?.address,
      subscriptionActive,
      subscription,
      products: response.products,
      intakeForms: response.intakeForms,
    }));

    if (subscriptionActive && !isBaselineProduct(searchParams.product)) {
      // Clear product if there's already a subscription, and the user isn't purchasing baseline.
      // This is because baseline is not a membership, so we will never be able to use `subscription`
      // to identify it. Right now, established members can't purchase baseline, but if we ever change
      // that, this line of code shouldn't require a change.
      setParams({ product: undefined });
    }
  });
};
