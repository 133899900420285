import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { profilesApi } from '~modules/api';
import { creditCardAtom, profileAtom } from '~modules/state';

import { logAndCapture, logError } from '../logger';

export const useCreatePaymentMethod = () => {
  const profileState = useRecoilValue(profileAtom);
  const stripe = useStripe();
  const elements = useElements();

  return useRecoilCallback(
    callbackInterface => async () => {
      if (!profileState.uuid) {
        logAndCapture("profile uuid is not specified, so can't upload the insurance card.");

        return;
      }

      if (!stripe || !elements) {
        logError("Stripe.js hasn't yet loaded. Make sure to disable form submission until Stripe.js has loaded.");
        throw new Error('There was an error when creating the payment method.');
      }

      // Trigger form validation and wallet collection.
      const { error: submitError } = await elements.submit();

      if (submitError) {
        throw new Error(submitError.message);
      }

      // Create the PaymentMethod using the details collected by the Payment Element.
      const { error: createPaymentMethodError, paymentMethod } = await stripe.createPaymentMethod({ elements });

      if (createPaymentMethodError) {
        logError(createPaymentMethodError);
        throw new Error(createPaymentMethodError.message);
      }

      callbackInterface.set(creditCardAtom, prevState => ({ ...prevState, paymentMethod: paymentMethod.id }));

      await profilesApi.attachStripeCustomer(profileState.uuid, paymentMethod.id)();

      return paymentMethod.id;
    },
    [elements, profileState.uuid, stripe]
  );
};
