import { useTranslation } from '@almond/localization';
import { formatPriceInCents } from '@almond/utils';

import { isBaselineProduct, isVirtualMembershipProduct } from '~modules/product';

import type { ProductOut } from '@almond/api-types';

export const usePricingExplanationTitle = (product: ProductOut | undefined) => {
  const { t } = useTranslation();
  const defaultAmount = product?.price?.amount;
  const defaultPrice = formatPriceInCents(defaultAmount ?? 0, true);
  const isBaseline = isBaselineProduct(product);
  const isVirtualMembership = isVirtualMembershipProduct(product);

  const title = (() => {
    if (isBaseline) return t('pricingExplanation.baseline.title', { price: defaultPrice });
    if (isVirtualMembership) return t('pricingExplanation.virtualMembership.title');

    return t('pricingExplanation.gynecologyMembership.title');
  })();

  const subtitle = (() => {
    if (isBaseline) return t('pricingExplanation.baseline.subtitle');
    if (isVirtualMembership) return t('pricingExplanation.virtualMembership.subtitle');

    return t('pricingExplanation.gynecologyMembership.subtitle');
  })();

  return { title, subtitle };
};
