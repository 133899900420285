// Make sure no additional URL processing happens until the Auth0
// query params have been consumed and the `redirectCallback()` function
// has run.

import React from 'react';

import { ActivityIndicator } from '@almond/ui';

import { Background } from '~modules/ui';

export default () => {
  return (
    <Background>
      <ActivityIndicator />
    </Background>
  );
};
