import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { useRecoilValue } from 'recoil';

import { patientsApi } from '~modules/api';
import { MainForm, Signature, useForm } from '~modules/forms';
import { useDetermineFirstMonthFree } from '~modules/profile';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { userAtom } from '~modules/state';

import type { FormValues } from '@almond/ui';
import type { CONSENT_SIGNATURE_PAGE_NAME, ConsentSignatureFormValues } from '~types';

export const ConsentSignaturePage: React.FC = () => {
  const { t } = useTranslation();
  const { dispatch } = useRouteNavigation<typeof CONSENT_SIGNATURE_PAGE_NAME>();
  const { doAsync, isLoading } = useAsync();
  const userState = useRecoilValue(userAtom);
  const formReturn = useForm({});

  // This hook will remove the FIRSTMONTHFREE promotion code from the credit card state
  // if required. Otherwise, it'll do nothing.
  useDetermineFirstMonthFree(false);

  const handleClearPress = useCallback(() => {
    formReturn.setValue('signature', undefined);
  }, [formReturn]);

  const handleSubmit = useCallback(
    (values: FormValues<ConsentSignatureFormValues>) => {
      const toCall = async (): Promise<void> => {
        if (!userState.user) return;

        await patientsApi.createConsent(values.signature);

        try {
          const insurance = await patientsApi.retrieveInsurance();

          await dispatch('submit', insurance);
        } catch (e) {
          await dispatch('submit', { memberId: undefined, isAccepted: false });
        }
      };

      doAsync(toCall);
    },
    [doAsync, userState.user, dispatch]
  );

  return (
    <MainForm
      id="consentSignature"
      title={t('consentSignature.title')}
      submitButtonTitle={t('consentSignature.submitTitle')}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      requiredFields={['signature']}
      formReturn={formReturn}
    >
      <Signature name="signature" onClearPress={handleClearPress} />
    </MainForm>
  );
};
