import type { PatientProductOut, PatientTypeformOut, ProductOut } from '@almond/api-types';

export function isBaselineProduct(product: string | undefined | null): product is 'baseline';
export function isBaselineProduct(product: undefined | null | ProductOut): boolean;
export function isBaselineProduct(product: string | undefined | null | ProductOut): boolean {
  const productKey = typeof product === 'string' ? product : product?.almondProductKey;

  return productKey === 'baseline';
}

export function isVirtualMembershipProduct(product: string | undefined | null): product is 'virtual_membership';
export function isVirtualMembershipProduct(product: undefined | null | ProductOut): boolean;
export function isVirtualMembershipProduct(product: string | undefined | null | ProductOut): boolean {
  const productKey = typeof product === 'string' ? product : product?.almondProductKey;

  return productKey === 'virtual_membership';
}

export function hasBaselineProduct(products: readonly PatientProductOut[] | undefined): boolean {
  if (!products) {
    return false;
  }

  return products.some(product => isBaselineProduct(product.almondProductKey));
}

export function isBaselineIntakeFormCompleted(intakeForms: readonly PatientTypeformOut[] | undefined): boolean {
  if (!intakeForms) {
    return false;
  }

  return intakeForms.some(form => form.formType === 'baseline_intake');
}
