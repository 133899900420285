import React from 'react';
import { Text as ReactText } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Link, Text, useTheme } from '@almond/ui';
import { useRecoilValue } from 'recoil';

import { useVisitReasons } from '~modules/appointment';
import { useRedirectBackToArtemis } from '~modules/integration';
import { useIsBaselineProduct } from '~modules/product';
import { userAtom } from '~modules/state';

import themedStyles from './styles';

import type { HttpRequestError } from '~modules/errors';

const ActionLink = (props: any) => {
  if (props.href) {
    return <ReactText {...props} />;
  }

  return <Link {...props} />;
};

export type FormErrorProps = {
  error?: HttpRequestError;
  patientUuid: string | null;
};

const FormError: React.FC<FormErrorProps> = props => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { backToArtemisLinkProps } = useRedirectBackToArtemis();
  const isBaseline = useIsBaselineProduct();
  const { isAdmin } = useRecoilValue(userAtom);
  const { visitReason } = useVisitReasons();

  let content = null;

  const linkProps =
    props.patientUuid && isAdmin
      ? {
          href: (() => {
            // It would be very complex to update all the Recoil state correctly to switch from the
            // new member flow to the returning member flow. It's likely (either now or over time in the
            // future) that we would forget to update something and cause the application state to be
            // incorrect.
            // Instead, let's just create a "returning patient" URL and do a hard reload navigation to that
            // URL. This resets the Recoil state from application bootstrap. This flow is used infrequently
            // enough (and only by Care Guides) that the UX tradeoff is worth the reduced code complexity.
            const urlObject = new URL(window.location.href);

            const clearVisitReason = !visitReason || !visitReason.memberTypes.includes('established');

            urlObject.pathname = clearVisitReason ? '/' : '/scheduling';
            urlObject.searchParams.set('patient_uuid', props.patientUuid);
            urlObject.searchParams.delete('routing_config_id');

            // This lets us stay on the Visit Categories page if the visit reason would
            // only be valid for a new member. This should NOT be hit for
            // maternity/pcos/etc that can be admin-booked for new AND established. In
            // those cases, go directly to the scheduling page.
            if (clearVisitReason) {
              urlObject.searchParams.delete('visit_reason');
              urlObject.searchParams.delete('category');
            }

            return urlObject.toString();
          })(),
        }
      : backToArtemisLinkProps(isBaseline ? 'messages?input_hint=baseline_signup' : '/');

  if (!props.error) return null;

  if (props.error?.errorCode === 'account_already_exists') {
    const reason: 'email' | 'phone' = props.error?.details?.errors[0]?.data?.reason || 'email';
    const humanReadableReason = t(`demographic.error.${reason}`);

    if (isAdmin) {
      content = (
        <>
          {t('demographic.error.adminPart1', { identifier: humanReadableReason })}
          <ActionLink {...linkProps} style={styles.signIn} testID="EmailFoundLink">
            {t('demographic.error.adminPart2')}
          </ActionLink>
          {t('demographic.error.adminPart3')}
        </>
      );
    } else if (reason === 'phone') {
      content = <>{t('demographic.error.contactCareTeam')}</>;
    } else {
      content = (
        <>
          {t('demographic.error.part1')}
          <ActionLink {...linkProps} style={styles.signIn} testID="EmailFoundLink">
            {t('demographic.error.signIn')}
          </ActionLink>
          {t('demographic.error.part2')}
        </>
      );
    }
  } else {
    content = props.error.message;
  }

  return <Text style={styles.formError}>{content}</Text>;
};

export default FormError;
