import { useCallback, useRef, useState } from 'react';

import { HttpRequestError, RuntimeError, useError } from '~modules/errors';

import { logError } from '../logger';

import type { AppError } from '~modules/errors';

export type UseAsyncConfig = {
  setIsLoading?: (isLoading: boolean) => void;
};

export type UseAsyncOptions<T> = {
  setResult?: (result: T | null) => void;
  setError?: (error: AppError | null) => void;
};

const useAsync = (config: UseAsyncConfig = {}) => {
  const { setIsLoading } = config;
  const [isLoadingCount, setIsLoadingCount] = useState(0);
  const displayError = useError();

  // Include stack in error logging so we can tell what component this was rendered inside of
  const stackRef = useRef<string>();

  // Only calculate stack once, but must do it at root of hook. Inside the catch() below,
  // we're in an async function so the stack is completely different
  if (!stackRef.current) stackRef.current = new Error().stack;

  const doAsync = useCallback(
    async <T>(toCall: () => Promise<T>, options: UseAsyncOptions<T> = {}) => {
      const { setResult, setError } = options;

      try {
        setIsLoading?.(true);
        setIsLoadingCount(count => count + 1);
        setError?.(null);

        const result = await toCall();

        setResult?.(result);
        setIsLoading?.(false);
        setIsLoadingCount(count => count - 1);

        return result;
      } catch (error: any) {
        // If error is an instance of RuntimeError or HttpRequestError, we know it's coming from some other part of our
        // application and should contain all necessary information.
        // If the error is anything else, we just parse it into unknown RuntimeError.
        const isErrorAlreadyParsed = error instanceof RuntimeError || error instanceof HttpRequestError;
        const parsedError = isErrorAlreadyParsed ? error : RuntimeError.fromOriginal(error, 'UnknownException');

        setIsLoading?.(false);
        setIsLoadingCount(count => count - 1);

        logError(parsedError || error || 'Unknown useAsync error', stackRef.current);
        setError?.(parsedError as AppError);
        displayError(parsedError.message);

        return undefined;
      }
    },
    [displayError, setIsLoading]
  );

  return { doAsync, isLoading: Boolean(isLoadingCount) };
};

export default useAsync;
