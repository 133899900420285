import React, { useState } from 'react';

import { useTranslation } from '@almond/localization';
import { useRecoilValue } from 'recoil';

import { FEEDBACK_PATH, feedbackApi } from '~modules/api';
import { useAsync } from '~modules/promises';
import { patientAtom } from '~modules/state';

import { useRedirectBackToArtemis } from '../../hooks';
import { logError } from '../../logger';
import { AbandonModal } from '../AbandonModal';
import { ConfirmationModal } from '../ConfirmationModal';
import { FeedbackModal } from '../FeedbackModal';

type ActiveModal = {
  id: 'abandon' | 'feedback' | 'confirmation';
  onBeforeSubmit?: () => Promise<string>;
};

export type AbandonModalWrapperProps = {
  isVisible: boolean;
  onRequestClose: () => void;
  onLeave: () => void;
};

export const AbandonModalWrapper = (props: AbandonModalWrapperProps) => {
  const { isVisible, onRequestClose, onLeave } = props;
  const [activeModal, setActiveModal] = useState<ActiveModal>({ id: 'abandon' });
  const [feedbackUuid, setFeedbackUuid] = useState<string>();
  const { doAsync } = useAsync();
  const { redirectBackToArtemis } = useRedirectBackToArtemis();
  const { t } = useTranslation();
  const { patientUuid, isNewMember } = useRecoilValue(patientAtom);

  if (!isVisible) return null;

  const handlePress = (key: string, text: string) => {
    const toCall = async () => {
      const patientResponse: { text: string; patientUuid?: string } = { text };

      if (!isNewMember) {
        patientResponse.patientUuid = patientUuid;
      }

      try {
        const response = await feedbackApi.createFeedback()({
          eventType: 'abandon',
          response: patientResponse,
        });

        setFeedbackUuid(response.uuid);

        if (key === 'somethingElse') {
          setActiveModal({ id: 'feedback' });
        } else if (key === 'contactUs') {
          onRequestClose();
          redirectBackToArtemis('/messages');
        } else {
          setActiveModal({ id: 'confirmation' });
        }
      } catch (err) {
        if (key === 'somethingElse') {
          setActiveModal({
            id: 'feedback',
            onBeforeSubmit: async () => {
              const response = await feedbackApi.createFeedback()({
                eventType: 'abandon',
                response: patientResponse,
              });

              return response.uuid;
            },
          });
        } else {
          // Don't block user from completing their action if the API request fails, but log
          // a useful error to Sentry
          logError(`Call to abandon ${FEEDBACK_PATH} failed`, `Payload: ${JSON.stringify(patientResponse)}`);
        }
      }
    };

    doAsync(toCall);
  };

  const handleRequestClose = () => {
    setActiveModal({ id: 'abandon' });
    onRequestClose();
  };

  const feedbackModalUuid = feedbackUuid || activeModal.onBeforeSubmit;

  return (
    <>
      <AbandonModal
        isVisible={activeModal.id === 'abandon'}
        onRequestClose={handleRequestClose}
        onPress={handlePress}
      />
      {feedbackModalUuid && (
        <FeedbackModal
          isVisible={activeModal.id === 'feedback'}
          onRequestClose={handleRequestClose}
          feedbackUuid={feedbackModalUuid}
          onSubmit={() => setActiveModal({ id: 'confirmation' })}
          eventType="abandon"
          onBack={() => setActiveModal({ id: 'abandon' })}
        />
      )}
      <ConfirmationModal
        isVisible={activeModal.id === 'confirmation'}
        onRequestClose={handleRequestClose}
        onPress={() => {
          setActiveModal({ id: 'abandon' });
          onLeave();
        }}
        submitTitle={t(isNewMember ? 'backToWebsite' : 'backToDashboard')}
      />
    </>
  );
};
