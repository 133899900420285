import React from 'react';
import { View } from 'react-native';

import { LocalImage, MaterialIcon, useTheme } from '@almond/ui';

import { LabTest, useImages } from '~assets';
import { isBaselineProduct, isVirtualMembershipProduct, useStripeProduct } from '~modules/product';

import themedStyles from './styles';

export const ProductIcon = () => {
  const { product } = useStripeProduct();
  const [styles] = useTheme(themedStyles);
  const images = useImages();

  if (isBaselineProduct(product)) {
    return (
      <View style={styles.cartItemSvgContainer}>
        <LabTest height={28} width={20} style={styles.cartItemIcon} />
      </View>
    );
  }

  if (isVirtualMembershipProduct(product)) {
    return (
      <View style={styles.cartItemSvgContainer}>
        <MaterialIcon source="camera" size={25} color="secondaryTextDark" style={styles.cartItemIcon} />
      </View>
    );
  }

  return <LocalImage images={images} source="plant1" style={styles.cartItemImage} />;
};
