import React, { useCallback } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { ConnectedTextInput, Text, useTheme } from '@almond/ui';

import { MainForm } from '~modules/forms';
import { useIsBaselineProduct } from '~modules/product';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { accountSetupAtom } from '~modules/state';

import { useAddReferral } from '../../hooks';

import { themedStyles } from './styles';

import type { FormValues } from '@almond/ui';
import type { REFERRAL_PAGE_NAME, ReferralFormValues } from '~types';

export const ReferralPage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync, isLoading } = useAsync();
  const addReferral = useAddReferral();
  const { dispatch } = useRouteNavigation<typeof REFERRAL_PAGE_NAME>();
  const isBaseline = useIsBaselineProduct();
  const [styles] = useTheme(themedStyles);

  const handleSubmit = useCallback(
    (values: FormValues<ReferralFormValues>) => {
      const toCall = async (): Promise<void> => {
        await addReferral(values);
        await dispatch('submit');
      };

      doAsync(toCall);
    },
    [addReferral, dispatch, doAsync]
  );

  return (
    <MainForm
      id="referral"
      title={t('referral.title')}
      submitButtonTitle={isBaseline ? t('referral.continueTitle') : t('referral.submitTitle')}
      atom={accountSetupAtom}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      requiredFields={['referral']}
      size="M"
    >
      <View style={styles.container}>
        <View style={styles.input}>
          <Text size="m">{t('referral.referralTitle')}</Text>
          <ConnectedTextInput name="referral" placeholder={t('referral.referralPlaceholder')} testID="Referral" />
        </View>
        <View style={styles.input}>
          <Text size="m">{t('referral.referrerByNameTitle')}</Text>
          <ConnectedTextInput
            name="referrerByName"
            placeholder={t('referral.referrerByNamePlaceholder')}
            testID="ReferrerByName"
          />
        </View>
      </View>
    </MainForm>
  );
};
