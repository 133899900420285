import { useSetRecoilState } from 'recoil';

import { patientsApi } from '~modules/api';
import { useStripeMembership } from '~modules/product';
import { patientProgressAtom } from '~modules/state';

import { usePurchase } from './usePurchase';

export const useCreateSubscription = () => {
  const setPatientProgressState = useSetRecoilState(patientProgressAtom);
  const membershipType = useStripeMembership();

  return usePurchase({
    createPayment: async params => {
      const subscription = await patientsApi.createSubscription({
        ...params,
        firstMonthFree: params.firstMonthFree,
        paymentBehavior: 'default_incomplete',
        cancelAtPeriodEnd: false,
        membershipType: membershipType ?? 'gynecology_membership',
      });

      return {
        stripeId: subscription.stripeSubscriptionId,
        clientSecret: subscription.clientSecret,
        type: subscription.type,
      };
    },
    onSuccess: () => {
      setPatientProgressState(prevState => ({ ...prevState, subscriptionActive: true }));
    },
  });
};
