import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  container: {
    padding: sizes.SPACING_M,
    maxWidth: '100%',
  },

  subtitle: {
    textAlign: 'center',
    marginTop: sizes.SPACING_S,
    width: 520,
    maxWidth: '90%',
  },

  description: {
    backgroundColor: theme.background,
    padding: sizes.SPACING_L,
    borderRadius: sizes.SPACING_S,
    marginTop: sizes.SPACING_M,
  },

  buttons: {
    marginVertical: sizes.SPACING_M,
  },

  stars: {
    position: 'relative',
    top: -22,
    marginRight: 5,
  },

  banner: {
    borderWidth: 1,
    borderColor: theme.text,
    maxWidth: 'min(490px, 100%)' as any,
    paddingVertical: 20,
    borderRadius: 12,
    marginHorizontal: 'auto',
  },

  wideBanner: {
    ...getShadow(),
    backgroundColor: theme.background,
    maxWidth: 'min(500px, 100%)' as any,
    paddingVertical: 15,
    borderRadius: sizes.SPACING_M,
    marginHorizontal: 'auto',
  },

  annualContainer: {
    backgroundColor: theme.selectedOption,
    paddingVertical: sizes.SPACING_XS,
    paddingHorizontal: sizes.SPACING_S,
    marginTop: 10,
    borderRadius: sizes.SPACING_S,
    width: 82,
    alignSelf: 'center',
    alignItems: 'center',
  },

  annual: {
    color: theme.lightText,
  },

  priceContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginTop: sizes.SPACING_M,
  },

  oldPrice: {
    textDecorationLine: 'line-through',
    marginEnd: sizes.SPACING_S,
  },

  perYear: {
    alignSelf: 'flex-end',
    marginBottom: sizes.SPACING_XXS,
  },

  firstYear: {
    marginStart: sizes.SPACING_S,
  },

  limitedPromo: {
    color: theme.accent,
    textAlign: 'center',
    marginTop: sizes.SPACING_XS,
    paddingHorizontal: sizes.SPACING_L,
    marginHorizontal: 'auto',
  },

  sectionTitle: {
    marginTop: sizes.SPACING_M,
    paddingHorizontal: sizes.SPACING_L,
  },

  sectionDescription: {
    paddingHorizontal: sizes.SPACING_L,
    marginTop: sizes.SPACING_S,
  },

  baselineSectionItem: {
    marginTop: sizes.SPACING_M,
  },

  button: {
    marginTop: 30,
    alignSelf: 'center',
  },

  fixedWidthButton: {
    marginTop: 28,
    alignSelf: 'center',
    width: 160,
  },

  returningMember: {
    textAlign: 'center',
    color: theme.secondaryTextDark,
    alignSelf: 'center',
    marginTop: sizes.SPACING_M,
  },

  centerText: {
    textAlign: 'center',
  },

  selfCenter: {
    alignSelf: 'center',
  },

  errorMessage: {
    marginVertical: sizes.SPACING_M,
  },

  disclaimer: {
    marginTop: sizes.SPACING_S,
    textAlign: 'center',
    paddingHorizontal: sizes.SPACING_L,
  },
}));
