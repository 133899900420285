import React from 'react';

import { ErrorMessage } from '~modules/errors';
import { useRedirectBackToArtemis } from '~modules/integration';
import { Background } from '~modules/ui';

export const WrongUser = () => {
  const { redirectBackToArtemis } = useRedirectBackToArtemis();

  return (
    <Background>
      <ErrorMessage
        error={new Error('That link belongs to a different member.')}
        retryText="Go to my dashboard"
        onTryAgain={() => {
          redirectBackToArtemis();
        }}
      />
    </Background>
  );
};
