/* eslint-disable @typescript-eslint/naming-convention */

import type { DateInputFormat } from '@almond/date-and-time';
import type { UnitTypeLong } from 'dayjs';

export const DATE_REGEX_MAP: { [key in DateInputFormat]: RegExp } = {
  'MM/YY': /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
  'MM/YYYY': /(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
  'MM/DD/YYYY': /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
  'MM/DD/YY': /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/([0-9]{2})$/,
};

export const DATE_FORMAT_LENGTH_MAP: { [key: number]: DateInputFormat } = {
  5: 'MM/YY',
  7: 'MM/YYYY',
  10: 'MM/DD/YYYY',
  8: 'MM/DD/YY',
};

export const DATE_COMPARISON_UNIT_MAP: { [key in DateInputFormat]: UnitTypeLong } = {
  'MM/YY': 'month',
  'MM/YYYY': 'month',
  'MM/DD/YYYY': 'day',
  'MM/DD/YY': 'day',
};
