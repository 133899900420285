import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { integrationAtom, userAtom } from '~modules/state';

export const useShouldDisplayIdleModal = () => {
  const { isAdmin } = useRecoilValue(userAtom);
  const searchParams = useLocalSearchParams();
  const integrationState = useRecoilValue(integrationAtom);

  return (
    !isAdmin &&
    !searchParams.booking_uuid &&
    !integrationState.isIdleModalDisabled &&
    !integrationState.isAbandonModalVisible
  );
};
