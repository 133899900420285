import { useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalSearchParams } from 'expo-router';

import { useGetAppState } from '~modules/state';

import { log } from '../logger';

import type { RedirectLoginOptions } from '~types';

export const useLoginWithRedirect = () => {
  const { loginWithRedirect } = useAuth0();
  const getAppState = useGetAppState();
  const searchParams = useGlobalSearchParams();

  return useCallback(
    async (options: RedirectLoginOptions = {}) => {
      const { dispatch, authorizationParams, openUrl, skipRestoreRecoilState, ...restOptions } = options;

      log('Login with redirect has been called with the next options:', options);

      const appState = await getAppState(!skipRestoreRecoilState, dispatch);

      log('App state will be stored for the current user session:', appState);

      await loginWithRedirect({
        authorizationParams: {
          prompt: 'login',
          screen_hint: 'login',
          login_hint: searchParams.email,
          ...authorizationParams,
        },
        appState,
        openUrl(url) {
          window.history.replaceState(
            {
              ...(window.history.state || {}),
              navigatingToAuth0: true,
            },
            '',
            window.location.href
          );

          if (openUrl) {
            openUrl(url);
          } else {
            window.location.assign(url);
          }
        },
        ...restOptions,
      });

      await new Promise((_, reject) => {
        // If the redirect to Auth0 hasn't happened in 10 seconds,
        // assume there's an error.
        setTimeout(() => {
          reject(new Error('Redirecting to the login page took too long. Please try again.'));
        }, 10000);
      });
    },
    [searchParams.email, getAppState, loginWithRedirect]
  );
};
