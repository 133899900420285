import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { SENTRY_UNMASK, useTheme } from '@almond/ui';
import { useRecoilValue } from 'recoil';

import { patientAtom } from '~modules/state';

import { BaseModal } from '../BaseModal';
import { ModalButton } from '../ModalButton';

import { themedStyles } from './styles';

import type { BaseModalProps } from '../BaseModal';

export type AbandonModalProps = Omit<BaseModalProps, 'title' | 'subtitle'> & {
  onPress: (key: string, text: string) => void;
};

const newMemberOptions = [
  { key: 'noProviders' as const, text: 'abandonModal.newMemberOptions.noProviders' as const },
  { key: 'noDays' as const, text: 'abandonModal.newMemberOptions.noDays' as const },
  { key: 'notSure' as const, text: 'abandonModal.newMemberOptions.notSure' as const },
  { key: 'somethingElse' as const, text: 'abandonModal.newMemberOptions.somethingElse' as const },
];

const establishedMemberOptions = [
  { key: 'noProviders' as const, text: 'abandonModal.establishedMemberOptions.noProviders' as const },
  { key: 'noDays' as const, text: 'abandonModal.establishedMemberOptions.noDays' as const },
  { key: 'contactUs' as const, text: 'abandonModal.establishedMemberOptions.contactUs' as const },
];

export const AbandonModal = (props: AbandonModalProps) => {
  const { onPress, ...restProps } = props;
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { isNewMember } = useRecoilValue(patientAtom);
  const [pressed, setPressed] = useState<(typeof newMemberOptions | typeof establishedMemberOptions)[number]['key']>();

  useEffect(() => {
    if (!restProps.isVisible) {
      setPressed(undefined);
    }
  }, [restProps.isVisible]);

  return (
    <BaseModal {...restProps} title={t('abandonModal.title')} subtitle={t('abandonModal.subtitle')}>
      <View style={styles.container}>
        {(isNewMember ? newMemberOptions : establishedMemberOptions).map((option, index) => (
          <ModalButton
            key={index}
            onPress={() => {
              onPress(option.key, t(option.text));
              setPressed(option.key);
            }}
            testID={`AbandonModal-${option.key}`}
            style={SENTRY_UNMASK}
            isLoading={pressed === option.key}
          >
            {t(option.text)}
          </ModalButton>
        ))}
      </View>
    </BaseModal>
  );
};
