import { useCallback } from 'react';

import { useRecoilValue } from 'recoil';

import { patientsApi } from '~modules/api';
import { useProcessProfileResponse } from '~modules/profile/hooks';
import { profileAtom } from '~modules/state';

import { logAndCapture } from '../logger';
import { useProcessPatientResponse } from './useProcessPatientResponse';

export const useCreatePatient = () => {
  const processPatientResponse = useProcessPatientResponse();
  const processProfileResponse = useProcessProfileResponse();
  const profileState = useRecoilValue(profileAtom);

  return useCallback(async () => {
    if (!profileState.uuid) {
      logAndCapture("profile uuid is not specified so can't get or create the patient.");

      return;
    }

    const patientResponse = await patientsApi.createPatient(profileState.uuid);

    processProfileResponse(patientResponse.profile);
    processPatientResponse(patientResponse, null);
  }, [profileState.uuid, processProfileResponse, processPatientResponse]);
};
