import React, { useEffect } from 'react';

import { ActivityIndicator } from '@almond/ui';

import { useRedirectBackToArtemis } from '~modules/integration';

export const AllSetPage: React.FC = () => {
  const { redirectBackToArtemis } = useRedirectBackToArtemis();

  useEffect(() => {
    redirectBackToArtemis();
  }, [redirectBackToArtemis]);

  return <ActivityIndicator testID="AllSetIndicator" />;
};
