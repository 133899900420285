import type {
  ADDRESS_PAGE_NAME,
  AddressParams,
  PHARMACY_PAGE_NAME,
  PharmacyParams,
  REFERRAL_PAGE_NAME,
  ReferralParams,
} from './accountSetup';
import type {
  BOOK_APPOINTMENT_PAGE_NAME,
  BookAppointmentParams,
  CONFIRM_EMAIL_PAGE_NAME,
  CONFIRMED_PAGE_NAME,
  ConfirmedParams,
  ConfirmEmailParams,
  LAB_ORDERS_PAGE_NAME,
  LabOrdersParams,
  LEARN_MORE_PAGE_NAME,
  LearnMoreParams,
  NEW_MEMBER_SCHEDULING_PAGE_NAME,
  NewMemberSchedulingParams,
  RECOMMENDATION_PAGE_NAME,
  RecommendationParams,
  SCHEDULING_PAGE_NAME,
  SchedulingParams,
  TELL_US_MORE_PAGE_NAME,
  TellUsMoreParams,
  VISIT_CATEGORIES_PAGE_NAME,
  VISIT_REASONS_PAGE_NAME,
  VisitCategoriesParams,
  VisitReasonsParams,
} from './appointment';
import type {
  INFECTION_QUESTIONNAIRE_PAGE_NAME,
  INFECTION_REVIEWING_PAGE_NAME,
  INFECTION_URGENT_CARE_PAGE_NAME,
  InfectionQuestionnaireParams,
  InfectionReviewingParams,
  InfectionUrgentCareParams,
} from './curated';
import type {
  ALLERGIES_PAGE_NAME,
  AllergiesParams,
  BASELINE_INTAKE_PAGE_NAME,
  BaselineIntakeParams,
  DOCTOR_INFO_PAGE_NAME,
  DoctorInfoParams,
  DRUG_ALLERGIES_PAGE_NAME,
  DrugAllergiesParams,
  FAMILY_HISTORY_PAGE_NAME,
  FamilyHistoryParams,
  HEALTH_CONDITIONS_PAGE_NAME,
  HealthConditionsParams,
  HOSPITALIZATION_PAGE_NAME,
  HospitalizationParams,
  MEDICATIONS_PAGE_NAME,
  MedicationsParams,
  OCCUPATION_PAGE_NAME,
  OccupationParams,
  PERNICIOUS_HABITS_PAGE_NAME,
  PerniciousHabitsParams,
  PREGNANCIES_PAGE_NAME,
  PregnanciesParams,
  PRIMARY_CARE_PROVIDER_PAGE_NAME,
  PrimaryCareProviderParams,
  SURGERIES_PAGE_NAME,
  SurgeriesParams,
} from './history';
import type {
  ALL_SET_PAGE_NAME,
  AllSetParams,
  CONFIRM_PURCHASE_PAGE_NAME,
  ConfirmPurchaseParams,
  CONSENT_SIGNATURE_PAGE_NAME,
  ConsentSignatureParams,
  CREATE_PATIENT_PAGE_NAME,
  CreatePatientParams,
  GET_STARTED_PAGE_NAME,
  GetStartedParams,
  PATIENT_LOGIN_PAGE_NAME,
  PatientLoginParams,
} from './patient';
import type {
  CASH_RATES_PAGE_NAME,
  CashRatesParams,
  CREDIT_CARD_PAGE_NAME,
  CreditCardParams,
  DEMOGRAPHIC_PAGE_NAME,
  DemographicParams,
  INSURANCE_ID_PAGE_NAME,
  INSURANCE_NOT_ACCEPTED_PAGE_NAME,
  INSURANCE_PAGE_NAME,
  InsuranceIdParams,
  InsuranceNotAcceptedParams,
  InsuranceParams,
  PHONE_VERIFICATION_PAGE_NAME,
  PhoneVerificationParams,
  PRICING_EXPLANATION_PAGE_NAME,
  PricingExplanationParams,
} from './profile';

type AccountSetupParams = {
  [ADDRESS_PAGE_NAME]: AddressParams;
  [PHARMACY_PAGE_NAME]: PharmacyParams;
  [REFERRAL_PAGE_NAME]: ReferralParams;
};

type AppointmentParams = {
  [BOOK_APPOINTMENT_PAGE_NAME]: BookAppointmentParams;
  [CONFIRMED_PAGE_NAME]: ConfirmedParams;
  [CONFIRM_EMAIL_PAGE_NAME]: ConfirmEmailParams;
  [LAB_ORDERS_PAGE_NAME]: LabOrdersParams;
  [LEARN_MORE_PAGE_NAME]: LearnMoreParams;
  [NEW_MEMBER_SCHEDULING_PAGE_NAME]: NewMemberSchedulingParams;
  [RECOMMENDATION_PAGE_NAME]: RecommendationParams;
  [SCHEDULING_PAGE_NAME]: SchedulingParams;
  [TELL_US_MORE_PAGE_NAME]: TellUsMoreParams;
  [VISIT_CATEGORIES_PAGE_NAME]: VisitCategoriesParams;
  [VISIT_REASONS_PAGE_NAME]: VisitReasonsParams;
};

type CuratedParams = {
  [INFECTION_QUESTIONNAIRE_PAGE_NAME]: InfectionQuestionnaireParams;
  [INFECTION_REVIEWING_PAGE_NAME]: InfectionReviewingParams;
  [INFECTION_URGENT_CARE_PAGE_NAME]: InfectionUrgentCareParams;
};

type HistoryParams = {
  [ALLERGIES_PAGE_NAME]: AllergiesParams;
  [DOCTOR_INFO_PAGE_NAME]: DoctorInfoParams;
  [DRUG_ALLERGIES_PAGE_NAME]: DrugAllergiesParams;
  [FAMILY_HISTORY_PAGE_NAME]: FamilyHistoryParams;
  [HEALTH_CONDITIONS_PAGE_NAME]: HealthConditionsParams;
  [HOSPITALIZATION_PAGE_NAME]: HospitalizationParams;
  [MEDICATIONS_PAGE_NAME]: MedicationsParams;
  [OCCUPATION_PAGE_NAME]: OccupationParams;
  [PERNICIOUS_HABITS_PAGE_NAME]: PerniciousHabitsParams;
  [PREGNANCIES_PAGE_NAME]: PregnanciesParams;
  [SURGERIES_PAGE_NAME]: SurgeriesParams;
  [PRIMARY_CARE_PROVIDER_PAGE_NAME]: PrimaryCareProviderParams;
  [BASELINE_INTAKE_PAGE_NAME]: BaselineIntakeParams;
};

type PatientParams = {
  [ALL_SET_PAGE_NAME]: AllSetParams;
  [CONFIRM_PURCHASE_PAGE_NAME]: ConfirmPurchaseParams;
  [CONSENT_SIGNATURE_PAGE_NAME]: ConsentSignatureParams;
  [CREATE_PATIENT_PAGE_NAME]: CreatePatientParams;
  [GET_STARTED_PAGE_NAME]: GetStartedParams;
  [PATIENT_LOGIN_PAGE_NAME]: PatientLoginParams;
};

type ProfileParams = {
  [CASH_RATES_PAGE_NAME]: CashRatesParams;
  [CREDIT_CARD_PAGE_NAME]: CreditCardParams;
  [DEMOGRAPHIC_PAGE_NAME]: DemographicParams;
  [INSURANCE_PAGE_NAME]: InsuranceParams;
  [INSURANCE_ID_PAGE_NAME]: InsuranceIdParams;
  [INSURANCE_NOT_ACCEPTED_PAGE_NAME]: InsuranceNotAcceptedParams;
  [PRICING_EXPLANATION_PAGE_NAME]: PricingExplanationParams;
  [PHONE_VERIFICATION_PAGE_NAME]: PhoneVerificationParams;
};

export type StackParamList = AccountSetupParams &
  CuratedParams &
  AppointmentParams &
  HistoryParams &
  PatientParams &
  ProfileParams;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface RootParamList extends StackParamList {}
  }
}

export * from './accountSetup';
export * from './appointment';
export * from './curated';
export * from './history';
export * from './navigation';
export * from './navigationWithoutRouting';
export * from './patient';
export * from './profile';
