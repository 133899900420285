import React, { useEffect } from 'react';

import { ActivityIndicator } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';

import { AUTH_STATES, useAuthState, useLoginWithRedirect } from '~modules/auth';
import { Background } from '~modules/ui';

import type { ComponentType, PropsWithChildren } from 'react';

/**
 * Enforce authentication when `is_new_member` is false, or
 * authentication is explicitly required. Otherwise, don't
 * enforce authentication.
 */
export const withEnforceAuthWhenNecessary = (
  Component: ComponentType<PropsWithChildren> = () => null,
  requireAuth?: boolean,
  loadingEle?: React.ReactNode
) => {
  const Wrapped = () => {
    const authState = useAuthState();
    const searchParams = useLocalSearchParams();
    const loginWithRedirect = useLoginWithRedirect();

    const doLogin =
      authState === AUTH_STATES.anonymous &&
      (requireAuth || searchParams.is_new_member === 'false' || searchParams.require_auth === 'true');

    useEffect(() => {
      if (doLogin) {
        loginWithRedirect({ skipRestoreRecoilState: true });
      }
    }, [loginWithRedirect, doLogin]);

    if (authState === AUTH_STATES.loading || doLogin) {
      return (
        loadingEle || (
          <Background>
            <ActivityIndicator />
          </Background>
        )
      );
    }

    return <Component />;
  };

  Wrapped.displayName = `withEnforceAuthWhenNecessary(${Component.displayName || Component.name || 'Component'})`;

  return Wrapped;
};

export const withEnforceAuth = (
  Component: ComponentType<PropsWithChildren> = () => null,
  loadingEle?: React.ReactNode
) => withEnforceAuthWhenNecessary(Component, true, loadingEle);
