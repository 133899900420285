import { useCallback } from 'react';

import { getAllowedInitialSearchParams, getInitialConfig, useRouteToConfig } from '~modules/routing';

export const useProcessPath = () => {
  const routeToConfig = useRouteToConfig();

  return useCallback(
    (isNewMember: boolean, urlSearchParams: URLSearchParams) => {
      const config = getInitialConfig(isNewMember);
      const searchParams = getAllowedInitialSearchParams(urlSearchParams);

      if (config) {
        routeToConfig(config, false, searchParams);
      }
    },
    [routeToConfig]
  );
};
