import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { SENTRY_UNMASK, useTheme } from '@almond/ui';
import { useRecoilState, useRecoilValue } from 'recoil';

import { isVirtualMembershipProduct, useStripeProduct } from '~modules/product';
import { useRouteNavigation } from '~modules/routing';
import { integrationAtom, patientProgressAtom } from '~modules/state';

import { BaseModal } from '../BaseModal';
import { ModalButton } from '../ModalButton';

import { themedStyles } from './styles';

const OUT_OF_STATE_TYPEFORM_URL = 'https://almondhealth.typeform.com/out-of-state';

export const VirtualMembershipModal = () => {
  const [integrationState, setIntegrationState] = useRecoilState(integrationAtom);
  const { subscriptionActive } = useRecoilValue(patientProgressAtom);
  const { product } = useStripeProduct();
  const { routingConfigId } = useRouteNavigation<any>();

  const isVisible = Boolean(
    !integrationState.wasVirtualMembershipModalInteractedWithYet &&
      routingConfigId?.startsWith('initial') &&
      !subscriptionActive &&
      isVirtualMembershipProduct(product)
  );
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);

  const onRequestClose = () => {
    setIntegrationState(prevState => ({
      ...prevState,
      wasVirtualMembershipModalInteractedWithYet: true,
    }));
  };

  const handleCaliforniaResident = () => {
    onRequestClose();
  };

  const handleNonCaliforniaResident = () => {
    window.location.href = OUT_OF_STATE_TYPEFORM_URL;
  };

  return (
    <BaseModal
      title={t('virtualMembership.modal.title')}
      subtitle={t('virtualMembership.modal.subtitle')}
      isVisible={isVisible}
      showClose={false}
    >
      <View style={styles.container}>
        <ModalButton onPress={handleCaliforniaResident} style={SENTRY_UNMASK} testID="VirtualMembershipModal-Yes">
          {t('yes')}
        </ModalButton>
        <ModalButton onPress={handleNonCaliforniaResident} style={SENTRY_UNMASK} testID="VirtualMembershipModal-No">
          {t('no')}
        </ModalButton>
      </View>
    </BaseModal>
  );
};
