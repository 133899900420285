import { useCallback } from 'react';

import { patientsApi } from '~modules/api';
import { useGetSubscription } from '~modules/payment';
import { useProcessProfileResponse } from '~modules/profile/hooks';

import { log } from '../logger';
import { useProcessPatientResponse } from './useProcessPatientResponse';

export const useLoadPatient = () => {
  const getSubscription = useGetSubscription();
  const processProfileResponse = useProcessProfileResponse();
  const processPatientResponse = useProcessPatientResponse();

  return useCallback(async () => {
    const patientResponse = await patientsApi.retrieve();
    const subscription = await getSubscription();

    log(`Patient ${patientResponse.uuid} has been loaded.`);

    processProfileResponse(patientResponse.profile);
    processPatientResponse(patientResponse, subscription);
  }, [getSubscription, processProfileResponse, processPatientResponse]);
};
