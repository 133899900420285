import React, { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'expo-router';
import { useRecoilState } from 'recoil';

import { axiosBase } from '~modules/api';
import { useLoadPatient } from '~modules/patient/hooks';
import { patientAtom } from '~modules/state';
import { useSetUser } from '~modules/user';

import { AUTH_STATES, AuthStateContextProvider, useAuthState } from './AuthStateContext';

import type { AuthStates } from './AuthStateContext';
import type { PropsWithChildren } from 'react';

export { AUTH_STATES, useAuthState };

export const AuthStateProvider = (props: PropsWithChildren) => {
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const [authState, setAuthState] = useState<AuthStates>(AUTH_STATES.init);
  const [error, setError] = useState<unknown>();
  const setUser = useSetUser();
  const loadPatient = useLoadPatient();
  const [patientState, setPatientState] = useRecoilState(patientAtom);
  const { setParams } = useRouter();

  // Set accessToken to { isAnonymous: true } to prevent fetching access token
  // May be perf benefit for users who have never logged in.
  useEffect(() => {
    if (authState !== AUTH_STATES.init) {
      return;
    }

    // If Auth0 is loading, it means we're on the /auth-redirect route, having just logged in.
    // If that's the case, and the auth isLoading flag is true, getAccessTokenSilently will throw
    // as if the user is not logged in. If we wait for isLoading to be false, getAccessTokenSilently
    // will correctly show the user logged in.
    if (isLoading) {
      return;
    }

    const getToken = async () => {
      // Prevent from running more than once, if isLoading changes
      setAuthState(AUTH_STATES.loading);

      try {
        await getAccessTokenSilently();
      } catch (e) {
        setAuthState(AUTH_STATES.anonymous);

        axiosBase.setInterceptors(null, null);

        return;
      }

      axiosBase.setInterceptors(getAccessTokenSilently, null);

      // Once we know the user is logged in, the require_auth
      // param has been satisfied, so remove it.
      setParams({ require_auth: undefined });

      try {
        const patientUuid = await setUser();

        axiosBase.setInterceptors(getAccessTokenSilently, patientUuid ?? null);

        // Once the patient is created in the new member flow, we still need to be able
        // to distinguish a new member from an established member. So set the value here,
        // before the patient is created.
        setPatientState(prevState => ({ ...prevState, isNewMember: !patientUuid }));

        if (patientUuid) {
          await loadPatient();
        }

        setAuthState(AUTH_STATES.authenticated);
      } catch (e) {
        setError(e);
      }
    };

    getToken();
  }, [authState, setUser, getAccessTokenSilently, loadPatient, setPatientState, isLoading, setParams]);

  // When patientState is restored when coming back from Auth0, update the interceptor
  useEffect(() => {
    if (authState === AUTH_STATES.authenticated) {
      axiosBase.setInterceptors(getAccessTokenSilently, patientState.patientUuid ?? null);
    }
  }, [authState, getAccessTokenSilently, patientState.patientUuid]);

  if (error) {
    throw error;
  }

  return <AuthStateContextProvider value={authState}>{props.children}</AuthStateContextProvider>;
};
