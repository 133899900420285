/* eslint-disable max-statements */

import { useLocalSearchParams } from 'expo-router';
import { useRecoilCallback } from 'recoil';

import { emailsApi } from '~modules/api';
import { is10thPregnancyVisit, isGroupMaternityProgram } from '~modules/appointment/services';
import { isBaselineProduct, isVirtualMembershipProduct, useStripeProduct } from '~modules/product';
import { appointmentParamsAtom, patientAtom, patientProgressAtom, profileAtom, userAtom } from '~modules/state';

import { log } from '../logger';
import { emailsUtilities } from '../services';

import type { EmailSideEffects, EmailTemplates, VisitReasonOut } from '@almond/api-types';
import type { SendEmailInParams } from '~types';

export const useSendEmail = () => {
  const searchParams = useLocalSearchParams();
  const { product } = useStripeProduct();

  return useRecoilCallback(
    callbackInterface => async (bookingUuid?: string, visitReason?: Pick<VisitReasonOut, 'subcategory' | 'code'>) => {
      const { isAdmin } = await callbackInterface.snapshot.getPromise(userAtom);
      const patientState = await callbackInterface.snapshot.getPromise(patientAtom);
      const profileState = await callbackInterface.snapshot.getPromise(profileAtom);
      const patientProgressState = await callbackInterface.snapshot.getPromise(patientProgressAtom);
      const appointmentParamsState = await callbackInterface.snapshot.getPromise(appointmentParamsAtom);

      const isNewMemberRemote = !!appointmentParamsState.isNewMemberRemote;

      // We don't need to send any emails for the lab flows.
      if (searchParams.visit_reason === 'assisted_lab') {
        return log("Lab flow is active, so we don't want to send any emails.");
      }

      if (visitReason && isGroupMaternityProgram(visitReason) && !is10thPregnancyVisit(visitReason)) {
        return log('Not sending onboarding email for group maternity classes other than the 10th visit.');
      }

      if (
        isAdmin &&
        !patientState.isNewMember &&
        patientProgressState.historyData &&
        patientProgressState.consent &&
        patientProgressState.pharmacy &&
        patientProgressState.address &&
        patientProgressState.referral &&
        patientProgressState.subscriptionActive
      ) {
        // Returning patients that have no additional info to fill out don't need an email
        return;
      }

      const sendEmailInParams: SendEmailInParams = {
        patient_uuid: patientState.patientUuid,
        appointment_uuid: searchParams.appointment_uuid,
        email: profileState.email,
        profile_uuid: profileState.uuid,
        booking_uuid: bookingUuid ?? searchParams.booking_uuid,
        product: product?.almondProductKey,
        visit_reason: visitReason?.code,
      };

      let emailTemplate: EmailTemplates | undefined;
      let sideEffect: EmailSideEffects | undefined;
      let linkPath: string | undefined;

      const membershipEmail = isVirtualMembershipProduct(product) ? 'virtual_welcome_email' : 'gyn_welcome_email';

      if (isBaselineProduct(product)) {
        linkPath = '/complete_on_boarding';
        emailTemplate = 'baseline_welcome_email';
        sideEffect = isAdmin ? 'set_password' : 'verify_email';
      } else if (visitReason && is10thPregnancyVisit(visitReason)) {
        // Group Maternity #10 - this case differs from the rest because we need to
        // determine if the patient is truly new or not with data that isn't is_new_member.
        // - loginsCount is used to determine if the patient needs to set a password or not.
        // - isNewMemberRemote is used to determine if the patient has had a past visit or not,
        //   which will inform which email template we send.
        linkPath = '/tell_us_more';
        emailTemplate = isNewMemberRemote ? membershipEmail : 'tell_us_more';
        sideEffect = patientState.loginsCount === 0 ? 'set_password' : 'verify_email';
      } else if (isAdmin && !patientState.isNewMember) {
        // Admin/Returning Patient
        linkPath = '/tell_us_more';
        emailTemplate = 'tell_us_more';
        sideEffect = 'verify_email';
      } else if (isAdmin && patientState.isNewMember) {
        // Admin/New Patient
        linkPath = '/tell_us_more';
        emailTemplate = membershipEmail;
        sideEffect = 'set_password';
      } else if (!patientProgressState.emailVerified) {
        // Patient/New Patient
        linkPath = '/complete_on_boarding';
        emailTemplate = patientState.isNewMember ? membershipEmail : 'complete_on_boarding';
        sideEffect = 'verify_email';
      }

      if (!emailTemplate || !sideEffect || !linkPath) return;

      await emailsApi.sendEmail(emailTemplate)(
        emailsUtilities.generateSendEmailIn(linkPath, sendEmailInParams, sideEffect)
      );
    },
    [product, searchParams.appointment_uuid, searchParams.booking_uuid, searchParams.visit_reason]
  );
};
