import { useCallback } from 'react';

import { useArtemisRedirectUrl } from './useArtemisRedirectUrl';

export const useRedirectBackToArtemis = () => {
  const artemisRedirectUrl = useArtemisRedirectUrl();

  const backToArtemisLinkProps = useCallback(
    (pathname?: string) => {
      // If the Artemis redirect URL is "_blank", that means that
      // Demi was opened in a new tab, and that there should still
      // exist an Artemis tab. In that case, we close the Demi tab
      // instead of redirecting back to Artemis within this tab.
      if (artemisRedirectUrl === '_blank') {
        return {
          onPress: () => {
            window.close();
          },
        };
      }

      // Redirecting back to artemis if it's specified inside the local state.
      const url =
        pathname && !artemisRedirectUrl.endsWith(pathname) ? `${artemisRedirectUrl}${pathname}` : artemisRedirectUrl;

      return {
        href: url,
      };
    },
    [artemisRedirectUrl]
  );

  const redirectBackToArtemis = useCallback(
    (pathname?: string) => {
      const { onPress, href } = backToArtemisLinkProps(pathname);

      if (href) {
        window.location.replace(href);
      } else if (onPress) {
        onPress();
      }
    },
    [backToArtemisLinkProps]
  );

  return { backToArtemisLinkProps, redirectBackToArtemis };
};
