import { patientAtom, patientProgressAtom } from '~modules/state';
import {
  ALL_SET_PAGE_NAME,
  BOOK_APPOINTMENT_PAGE_NAME,
  CREDIT_CARD_PAGE_NAME,
  PRICING_EXPLANATION_PAGE_NAME,
  SCHEDULING_PAGE_NAME,
} from '~types';

import type { RoutingConfig, StackParamList } from '~types';

export const testingRoutes = {
  id: 'testing' as const,
  initial: () => ({ name: SCHEDULING_PAGE_NAME }),
  routes: {
    [SCHEDULING_PAGE_NAME]: {
      on: {
        submit: async getState => {
          const { subscriptionActive } = await getState(patientProgressAtom);
          const { isNewMember } = await getState(patientAtom);

          if (!subscriptionActive && !isNewMember) {
            return { name: PRICING_EXPLANATION_PAGE_NAME };
          }

          return { name: BOOK_APPOINTMENT_PAGE_NAME };
        },
        curatedSubmit: () => {
          throw new Error('Not yet implemented');
        },
      },
    },
    [PRICING_EXPLANATION_PAGE_NAME]: {
      on: {
        submit: () => ({ name: CREDIT_CARD_PAGE_NAME }),
        learnMore: () => {
          throw new Error('LearnMore action only available for new member bookings');
        },
      },
    },
    [CREDIT_CARD_PAGE_NAME]: {
      on: {
        submit: () => ({ name: BOOK_APPOINTMENT_PAGE_NAME }),
      },
    },
    [BOOK_APPOINTMENT_PAGE_NAME]: {
      on: {
        submit: () => ({ name: ALL_SET_PAGE_NAME }),
        cardRequired: (_1, _2, error: string | undefined) => ({
          name: CREDIT_CARD_PAGE_NAME,
          params: { error },
        }),
      },
    },
    [ALL_SET_PAGE_NAME]: {
      reset: true,
      on: null,
    },
  },
} satisfies RoutingConfig<StackParamList>;
