import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { ActivityIndicator, Button, Link, List, Text, useTheme } from '@almond/ui';
import { formatPriceInCents } from '@almond/utils';
import { useRecoilValue } from 'recoil';

import { ErrorMessage } from '~modules/errors';
import { calculateRenewalPrice, useCalculatePrice } from '~modules/payment';
import { useStripeProduct } from '~modules/product';
import { useRouteNavigation } from '~modules/routing';
import { creditCardAtom, patientAtom } from '~modules/state';

import { unstable_styles as cssStyles } from './Content.module.css';

import themedStyles from './styles';

import type { PRICING_EXPLANATION_PAGE_NAME } from '~types';

export type MembershipProps = {
  onSubmitPress: () => void;
  isLoading: boolean;
  variant: 'virtualMembership' | 'gynecologyMembership';
};

export const Membership: React.FC<MembershipProps> = props => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { dispatch } = useRouteNavigation<typeof PRICING_EXPLANATION_PAGE_NAME>();
  const { product, isLoading, error, mutate } = useStripeProduct();
  const creditCardState = useRecoilValue(creditCardAtom);
  const { isNewMember } = useRecoilValue(patientAtom);
  const isFirstMonthFree = creditCardState.promotionCode?.code?.toLowerCase() === 'firstmonthfree';

  const {
    discountPrice,
    error: calculatePriceError,
    isLoading: isCalculatePriceLoading,
  } = useCalculatePrice(product, creditCardState);
  const renewalPrice =
    product &&
    calculateRenewalPrice(product, [creditCardState.promotionCode, creditCardState.friendReferralCode].filter(Boolean));

  if (isLoading || isCalculatePriceLoading) {
    return <ActivityIndicator />;
  }

  if (error || calculatePriceError || !product) {
    return (
      <ErrorMessage
        error={error || calculatePriceError}
        onTryAgain={() => mutate()}
        isFull={false}
        style={styles.errorMessage}
      />
    );
  }

  const defaultAmount = product.price.amount;
  const defaultPrice = formatPriceInCents(defaultAmount, true);
  const discountedPrice = formatPriceInCents(discountPrice, true);
  const isPriceDiscounted = defaultAmount !== discountPrice;
  const isPriceDiscountedNextYear = discountPrice === renewalPrice;

  return (
    <View style={styles.container}>
      <View style={styles.banner}>
        <View style={styles.annualContainer}>
          <Text style={styles.annual} fontStyle="bold" size="xs" role="heading" aria-level={2}>
            {t('pricingExplanation.content.annual')}
          </Text>
        </View>
        <View
          style={styles.priceContainer}
          // eslint-disable-next-line max-len
          aria-label={`${(isPriceDiscounted ? `Old price ${defaultPrice} per year, new price ` : '') + discountedPrice} ${isPriceDiscountedNextYear ? 'per year' : 'for the first year'}`}
        >
          {isPriceDiscounted && (
            <Text fontStyle="medium" size="xxxl" style={styles.oldPrice} aria-hidden>
              {defaultPrice}
            </Text>
          )}
          <Text fontStyle="medium" size="xxxl" aria-hidden>
            {discountedPrice}
          </Text>
          <Text
            style={[styles.perYear, !isPriceDiscountedNextYear && styles.firstYear]}
            size="xxxl"
            fontStyle="medium"
            aria-hidden
          >
            {t(`pricingExplanation.content.${isPriceDiscountedNextYear ? 'perYear' : 'firstYear'}`)}
          </Text>
        </View>
        {creditCardState.promotionCode?.code === 'firstmonthfree' && (
          <Text style={[styles.limitedPromo, cssStyles.textBalance]} size="m" fontStyle="bold">
            {t('pricingExplanation.limitedPromo')}
          </Text>
        )}
        <Text size="m" fontStyle="bold" style={styles.sectionTitle}>
          {t(`pricingExplanation.${props.variant}.subscriptionItemsTitle`)}
        </Text>
        <List.UnorderedList style={styles.sectionDescription}>
          {t(`pricingExplanation.${props.variant}.subscriptionItems`, { returnObjects: true }).map((item, index) => (
            <List.ListItem key={index}>
              <Text size="m">{item}</Text>
            </List.ListItem>
          ))}
        </List.UnorderedList>
        <Button
          type="primary"
          style={styles.button}
          onPress={props.onSubmitPress}
          isLoading={props.isLoading}
          isDisabled={props.isLoading}
          size="s"
          testID="Submit"
        >
          {t('pricingExplanation.submit')}
        </Button>
        <Text size="xs" fontStyle="bookItalic" style={[styles.disclaimer, cssStyles.textBalance]}>
          {t(`pricingExplanation.content.${isFirstMonthFree ? 'disclaimerFirstMonthFree' : 'disclaimer'}`)}
        </Text>
      </View>
      {isNewMember && (
        <Link
          url="#"
          onPress={async e => {
            e.preventDefault();
            await dispatch('learnMore');
          }}
          size="m"
          style={styles.returningMember}
        >
          {t('pricingExplanation.learnMore')}
        </Link>
      )}
    </View>
  );
};
