/* eslint-disable max-statements */

import { cypressUtilities } from '@almond/utils';
import { useStripe } from '@stripe/react-stripe-js';
import { useRecoilCallback } from 'recoil';

import { PaymentRequiredError } from '~modules/errors';
import { creditCardAtom } from '~modules/state';

import { logError } from '../logger';

export const useConfirmPayment = () => {
  const stripe = useStripe();

  return useRecoilCallback(
    callbackInterface => async (intentType?: string, clientSecret?: string, paymentMethod?: string) => {
      const errorMessage = (type: string) => `There was an error when confirming the payment (type ${type}).`;

      if (!stripe) {
        logError("Stripe.js hasn't yet loaded. Make sure to disable form submission until Stripe.js has loaded.");
        throw new Error(errorMessage('01'));
      }

      if (!clientSecret) {
        logError('Client secret was not returned from subscription, so cannot confirm the payment.');
        throw new Error(errorMessage('02'));
      }

      const creditCardState = await callbackInterface.snapshot.getPromise(creditCardAtom);

      // A payment can be confirmed just after a payment method was created and written to the local state.
      // In this case, we can pass the payment method into this function instead of reading it from the local state.
      const pm = paymentMethod || creditCardState.paymentMethod;

      if (!pm) {
        logError('Payment method is not defined.');
        throw new Error(errorMessage('03'));
      }

      // Not the best way to do it, but a quick one. It's required to test the resubscribe flow in Cypress.
      // The issue is that we're mocking the patient subscription to be cancelled, however,
      // when she books an appointment, then a real client secret is required.
      if (cypressUtilities.isCypressRunning() && clientSecret === 'TEST_CLIENT_SECRET') {
        return;
      }

      const confirmMethod = intentType === 'setup' ? stripe.confirmSetup : stripe.confirmPayment;

      const { error } = await confirmMethod({
        clientSecret,
        redirect: 'if_required',
        confirmParams: {
          payment_method: pm,
        },
      });

      if (error) {
        logError(error);

        if (error.type === 'card_error' || error.type === 'validation_error') {
          throw new PaymentRequiredError(undefined, error.message);
        } else {
          throw new Error(error.message);
        }
      }
    },
    [stripe]
  );
};
