import { useCallback } from 'react';

import env from '~env';

import { useRedirectCallback } from './useRedirectCallback';

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    navigatedBackFromAuth0: boolean;
  }
}

/**
 * When the user clicks the browser "back" button from Auth0, we want
 * to restore their state, so they don't have to restart the flow. This
 * hook detects that condition and restores the state from sessionStorage.
 */
export const useRestoreStateOnBack = () => {
  const redirectCallback = useRedirectCallback();

  return useCallback((): boolean => {
    if (!('sessionStorage' in window)) return false;
    const sessionKeys = Object.keys(window.sessionStorage);

    // Auth0 stores the state in sessionStorage with a key that ends with the client ID
    const auth0AppStateKey = sessionKeys.find(key => key.endsWith(env.AUTH0_CLIENT_ID || ''));

    if (!auth0AppStateKey) {
      return false;
    }

    const auth0StateString = window.sessionStorage.getItem(auth0AppStateKey);

    window.sessionStorage.removeItem(auth0AppStateKey);

    // If this was NOT a "back" operation, it was a fresh navigation to a Demi URL. We should
    // allow the user to bootstrap the application like normal, unless they were going "back"
    if (!window.navigatedBackFromAuth0) {
      return false;
    }

    const appState = auth0StateString ? JSON.parse(auth0StateString).appState : undefined;

    redirectCallback(appState, true);

    return true;
  }, [redirectCallback]);
};
