import { useCallback } from 'react';

import { useRecoilValue } from 'recoil';

import { profilesApi } from '~modules/api';
import { profileAtom } from '~modules/state';

import { logAndCapture } from '../logger';

import type { FormValues } from '@almond/ui';
import type { InsuranceIdFormValues } from '~types';

const useUploadInsuranceCard = (): ((values: FormValues<InsuranceIdFormValues>) => Promise<void>) => {
  const profileState = useRecoilValue(profileAtom);

  return useCallback(
    async (values: FormValues<InsuranceIdFormValues>) => {
      if (!profileState.uuid) {
        return logAndCapture("profile uuid is not specified, so can't upload the insurance card.");
      }

      await profilesApi.uploadInsuranceCardFront(profileState.uuid, values.card.front);

      if (values.card.back) await profilesApi.uploadInsuranceCardBack(profileState.uuid, values.card.back);
    },
    [profileState.uuid]
  );
};

export default useUploadInsuranceCard;
