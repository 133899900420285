import { LOG_DATE_TIME_FORMAT } from '@almond/date-and-time';
import { captureException, captureMessage } from '@sentry/browser';
import dayjs from 'dayjs';

import AppError from '~modules/errors/AppError';

import type { Contexts } from '@sentry/core';
import type { SentryMessageDetails } from '~types';

const formatTimestamp = (): string => dayjs().format(LOG_DATE_TIME_FORMAT);

const formatLogMessage = (
  category: string,
  message: string,
  includeTimestamp = true,
  includeFormatting = true
): string => {
  if (!includeFormatting) return `[${category}] ${message}`;

  const timestampPrefix = includeTimestamp ? `%c${formatTimestamp()} ` : '';

  return `${timestampPrefix}%c[${category}] %c${message}`;
};

const doLog = (category: string, message: string, details?: any): void => {
  const args = [formatLogMessage(category, message), 'color: #7b9bd1', 'color: #e97964; font-weight: bold', ''];

  if (details) args.push(details);

  // eslint-disable-next-line no-console
  console.log(...args);
};

export const log = (category: string, message: string, details?: any): void => {
  // Log to the console unless we're on prod or running as part of a test-suite
  if (process.env.EXPO_PUBLIC_ENV !== 'prod' && process.env.NODE_ENV !== 'test') {
    doLog(category, message, details);
  }
};

const removeOriginalStack = (error: AppError): AppError | undefined => {
  if (error.originalError instanceof Error) {
    if (error.originalError.stack) {
      return {
        ...error,
        originalError: {
          name: error.originalError.name,
          message: error.originalError.message,
        },
      } as AppError;
    }
  }

  return undefined;
};

const logAppError = (category: string, error: AppError, details?: string): void => {
  const errorMinusOriginalStack = removeOriginalStack(error);

  if (errorMinusOriginalStack) {
    doLog(category, `AppError received:\n${JSON.stringify(errorMinusOriginalStack, null, 2)}\n`, details);

    const originalError = error.originalError as Error;

    doLog(category, `Original Error's Stack:\n  ${originalError.stack?.replace(/\n/g, '\n  ')}\n`, details);
  } else {
    doLog(category, `AppError received:\n${JSON.stringify(error, Object.getOwnPropertyNames(error))}\n`, details);
  }

  if (error.stack) {
    doLog(category, `AppError's Stack:\n  ${error.stack.replace(/\n/g, '\n  ')}\n`);
  }
};

const logErrorObject = (category: string, error: Error, details?: string): void => {
  // If a stack is present that will contain the message so just log that
  if (error.stack) {
    doLog(category, error.stack, details);
  } else if (error.message) {
    // Otherwise there should be a message so log that
    const errorName = error.name || 'Error';

    doLog(category, `${errorName} received: ${error.message}`, details);
  } else {
    // Otherwise fallback to generically log the error in a way that Error objects support
    // (See: https://stackoverflow.com/a/26199752/1161972)
    const errorName = error.name || 'Error';

    doLog(category, `${errorName} received:\n${JSON.stringify(error, Object.getOwnPropertyNames(error), 2)}`, details);
  }
};

const logErrorToConsole = (category: string, error: Error | string | unknown, details?: string): void => {
  if (typeof error === 'string') {
    doLog(category, `Error string received: ${error}`, details);
  } else if (error instanceof AppError) {
    logAppError(category, error, details);
  } else if (error instanceof Error) {
    logErrorObject(category, error, details);
  } else {
    doLog(category, `Error received:\n${JSON.stringify(error, null, 2)}`, details);
  }
};

const sendErrorToSentry = (error: Error | string | unknown, details?: string): void => {
  if (typeof error === 'string') {
    captureMessage(error || 'Empty sentry message #1', scope => {
      // Standard context fields for all messages logged
      return scope.setContext('Details', { details });
    });
  } else {
    captureException(error || 'Empty sentry error #1', scope => {
      // Standard context fields for all messages logged

      if (error instanceof AppError && 'originalError' in error) {
        scope.setExtra('Original error', { originalError: JSON.stringify(error.originalError) });
      }

      return scope.setContext('Details', { details });
    });
  }
};

const isSentryContexts = (details: SentryMessageDetails): details is Contexts => {
  if (typeof details !== 'object') {
    return false;
  }

  // If every key in the details object contains a nested object, then treat the details object as a Contexts object
  return [...Object.values(details)].every(value => typeof value === 'object');
};

const sendLogToSentry = (category: string, message: string, details?: SentryMessageDetails): void => {
  const formattedLogMessage = formatLogMessage(category, message, false, false);

  captureMessage(formattedLogMessage || 'Empty sentry message #2', scope => {
    // Standard context fields for all messages logged
    scope.setContext('Message Context', {
      timestamp: formatTimestamp(),
      application: 'demi',
    });

    // Optional additional context values - either a map of separate contexts, or a single one
    if (details != null && Object.keys(details).length) {
      if (isSentryContexts(details)) {
        Object.entries(details).forEach(([key, context]) => scope.setContext(key, context || null));
      } else {
        scope.setContext('Message Details', details);
      }
    }

    return scope;
  });
};

export const logError = (category: string, error: Error | string | unknown, details?: string): void => {
  if (process.env.NODE_ENV === 'production') {
    // Send to Sentry in prod env
    sendErrorToSentry(error, details);
  } else if (process.env.NODE_ENV !== 'test') {
    // Log to the console unless we're running as part of a test-suite
    logErrorToConsole(category, error, details);
  }
};

export const logAndCapture = (category: string, message: string, details?: SentryMessageDetails): void => {
  if (process.env.NODE_ENV === 'production') {
    // Send to Sentry in prod env
    sendLogToSentry(category, message, details);
  } else if (process.env.NODE_ENV !== 'test') {
    // Log to the console unless we're running as part of a test-suite
    doLog(category, message, details);
  }
};
