import { createContext, useContext } from 'react';

export const AUTH_STATES = {
  loading: 'loading',
  init: 'init',
  anonymous: 'anonymous',
  authenticated: 'authenticated',
} as const;

export type AuthStates = (typeof AUTH_STATES)[keyof typeof AUTH_STATES];

const AuthStateContext = createContext<AuthStates>(AUTH_STATES.anonymous);

export const AuthStateContextProvider = AuthStateContext.Provider;

export const useAuthState = () => {
  const state = useContext(AuthStateContext);

  if (state === AUTH_STATES.init) {
    return AUTH_STATES.loading;
  }

  return state;
};
