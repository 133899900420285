import React from 'react';

import { LabOrdersPage } from '~modules/appointment';
import { Container } from '~modules/layout';
import { withRouteBehavior } from '~modules/navigation';
import { configurations } from '~modules/routing';

export default withRouteBehavior(LabOrdersPage, {
  allowDirectRouting: true,
  routingConfig: configurations.lab,
  forceSearchParams: {
    visit_reason: 'assisted_lab',
  },
  requireAuth: true,
  loadingEle: <Container id="labOrders" isLoading />,
  title: 'Lab Orders',
});
