import axios from 'axios';
import axiosRetry from 'axios-retry';
import { v4 } from 'uuid';

import * as axiosUtilities from './axiosUtilities';
import { axiosConfig, UUID_TEMPLATE } from './config';
import { log } from './logger';

import type { ApiRestEndpoint, Json, paths, RequestBody, RequestParams, SuccessResponse } from '@almond/api-types';
import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import type { AnyObject, InferType, Maybe, ObjectSchema } from 'yup';

export const instance: AxiosInstance = axios.create(axiosConfig);
const correlationId = v4();
let interceptorId: number | undefined;

// Exponential back-off retry delay between requests
axiosRetry(instance, {
  retryDelay: axiosRetry.exponentialDelay,
  onRetry: (retryCount: number, error: AxiosError, requestConfig: AxiosRequestConfig) => {
    log(`Retry attempt #${retryCount} for ${requestConfig.url}`, error.response?.status);
  },
});
export const setInterceptors = (
  getAccessTokenSilently: null | (() => Promise<string | null>),
  patientUuid: string | null
): void => {
  if (interceptorId !== undefined) {
    // We don't want to have multiple interceptors, so as soon as they updated,
    // we need to clear the previous ones.
    instance.interceptors.request.eject(interceptorId);
    interceptorId = undefined;
  }

  interceptorId = instance.interceptors.request.use(async config => {
    const updatedConfig = config;

    // We need to add x-correlation-id header to all Almond APIs.
    if (axiosUtilities.isAlmondApi(updatedConfig.baseURL)) {
      updatedConfig.headers['x-correlation-id'] = correlationId;
    }

    // If the access token is specified then we need to add to it to the headers.
    if (getAccessTokenSilently) {
      updatedConfig.headers.Authorization = `Bearer ${await getAccessTokenSilently()}`;
    }

    if (updatedConfig.url?.includes(UUID_TEMPLATE)) {
      // If patientUuid is specified then we need to replace the template in the url.
      if (patientUuid) updatedConfig.url = updatedConfig.url?.replace(UUID_TEMPLATE, patientUuid);
      else throw new Error(`Patient UUID is not defined, but is required for a request to ${updatedConfig.url}.`);
    }

    return updatedConfig;
  });
};

instance.interceptors.response.use(config => {
  log(`${config.status} ${config.config.url}`, config.data);

  return config;
});

type AxiosRequestConfigWithParams<Params = never> = Omit<AxiosRequestConfig, 'params'> & { params?: Params };

export const getWithValidator =
  <T extends Maybe<AnyObject>>(validator: ObjectSchema<T>) =>
  async (url: string, config?: AxiosRequestConfigWithParams<any>): Promise<InferType<typeof validator>> => {
    return instance
      .get(url, config)
      .then(r => r.data)
      .then(r => validator.validate(r))
      .catch(error => axiosUtilities.processAxiosError(error));
  };

export const get = <Path extends keyof paths>(
  url: Path,
  config?: AxiosRequestConfigWithParams<RequestParams<ApiRestEndpoint<Path, 'get'>>>
): Promise<SuccessResponse<ApiRestEndpoint<Path, 'get'>>> =>
  instance
    .get(url, config)
    .then(response => response.data)
    .catch(error => axiosUtilities.processAxiosError(error));

export const post =
  <Path extends keyof paths>(url: Path, config?: AxiosRequestConfigWithParams) =>
  (data?: RequestBody<ApiRestEndpoint<Path, 'post'>, Json>): Promise<SuccessResponse<ApiRestEndpoint<Path, 'post'>>> =>
    instance
      .post(url, data, config)
      .then(response => response.data)
      .catch(error => axiosUtilities.processAxiosError(error));

export const put =
  <Path extends keyof paths>(url: Path, config?: AxiosRequestConfigWithParams) =>
  (data?: RequestBody<ApiRestEndpoint<Path, 'put'>, Json>): Promise<SuccessResponse<ApiRestEndpoint<Path, 'put'>>> =>
    instance
      .put(url, data, config)
      .then(response => response.data)
      .catch(error => axiosUtilities.processAxiosError(error));

export const patch =
  <Path extends keyof paths>(url: Path, config?: AxiosRequestConfigWithParams) =>
  (
    data?: RequestBody<ApiRestEndpoint<Path, 'patch'>, Json>
  ): Promise<SuccessResponse<ApiRestEndpoint<Path, 'patch'>>> =>
    instance
      .patch(url, data, config)
      .then(response => response.data)
      .catch(error => axiosUtilities.processAxiosError(error));

export const del = <Path extends keyof paths>(
  url: Path,
  config?: AxiosRequestConfigWithParams
): Promise<SuccessResponse<ApiRestEndpoint<Path, 'delete'>>> =>
  instance
    .delete(url, config)
    .then(response => response.data)
    .catch(error => axiosUtilities.processAxiosError(error));

export const uploadFile = async (fileUri: string, uploadUrl: string): Promise<AxiosResponse> => {
  const blob = await axiosUtilities.toBlob(fileUri);
  const formData = new FormData();

  formData.append('file', blob);

  return instance.post(uploadUrl, formData, {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
