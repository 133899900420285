import { useTrackAnalyticsEvent } from '@almond/analytics';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilCallback } from 'recoil';

import { appointmentsApi, patientsApi } from '~modules/api';
import { useSendEmail } from '~modules/emails';
import { useCompleteQuestionnaire } from '~modules/questionnaire';
import { useRouteNavigation } from '~modules/routing';
import { appointmentParamsAtom } from '~modules/state';

import { logAndCapture } from '../logger';
import { appointmentUtilities, is10thPregnancyVisit, isGroupMaternityProgram } from '../services';

import type { VisitReasonOut } from '@almond/api-types';
import type { BOOK_APPOINTMENT_PAGE_NAME } from '~types';

const useCreateAppointmentBooking = () => {
  const searchParams = useLocalSearchParams();
  const completeQuestionnaire = useCompleteQuestionnaire();
  const { dispatch } = useRouteNavigation<typeof BOOK_APPOINTMENT_PAGE_NAME>();
  const sendEmail = useSendEmail();
  const trackAnalyticsEvent = useTrackAnalyticsEvent();

  return useRecoilCallback(
    callbackInterface => async (visitReason: Pick<VisitReasonOut, 'subcategory' | 'code'>) => {
      const appointmentParamsState = await callbackInterface.snapshot.getPromise(appointmentParamsAtom);
      const extraTags = appointmentUtilities.selectTags(visitReason.code);

      if (!searchParams.appointment_uuid) {
        return logAndCapture("`appointment_uuid` is not specified, so can't create the appointment booking.");
      }

      const response = await appointmentsApi.createAppointmentBooking(
        searchParams.appointment_uuid,
        isGroupMaternityProgram(visitReason),
        extraTags
      );

      // TODO make these requests execute in parallel, not series
      if (searchParams.visit_reason !== 'assisted_lab') {
        await completeQuestionnaire(response.appointment);
      }

      if (appointmentParamsState.labOrders?.length) {
        await appointmentsApi.submitLabOrders(response.appointment)({
          labOrderUuids: appointmentParamsState.labOrders,
        });
      }

      trackAnalyticsEvent('appointment_booked');
      await sendEmail(response.booking, visitReason);

      if (is10thPregnancyVisit(visitReason)) {
        // This is supposed to be the 10th visit, but the code is named "reunion"
        await patientsApi.sendTextMessage('reunion_confirmation_message');
      }

      try {
        const insurance = await patientsApi.retrieveInsurance();

        await dispatch('submit', insurance, { booking_uuid: response.booking });
      } catch (e) {
        await dispatch('submit', { memberId: undefined, isAccepted: false }, { booking_uuid: response.booking });
      }
    },
    [
      searchParams.appointment_uuid,
      searchParams.visit_reason,
      trackAnalyticsEvent,
      sendEmail,
      completeQuestionnaire,
      dispatch,
    ]
  );
};

export default useCreateAppointmentBooking;
