import type { PatientProductOut, PatientTypeformOut, SubscriptionOut } from '@almond/api-types';

export type PatientState = {
  isNewMember: boolean;
  patientUuid?: string;
  signature?: string;
  lastSeenTimestamp?: string;
  loginsCount?: number;
};

export type PatientProgressState = {
  emailVerified?: boolean;
  consent?: boolean;
  pharmacy?: boolean;
  referral?: boolean;
  historyData?: boolean;
  healthConditions?: boolean;
  medications?: boolean;
  drugAllergies?: boolean;
  allergies?: boolean;
  surgeries?: boolean;
  pregnancies?: boolean;
  hospitalization?: boolean;
  familyHistory?: boolean;
  occupation?: boolean;
  address?: boolean;
  subscriptionActive?: boolean;
  subscription?: SubscriptionOut | null;
  invoice?: boolean;
  products?: readonly PatientProductOut[];
  intakeForms?: readonly PatientTypeformOut[];
};
