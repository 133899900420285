import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Button, Text, useTheme } from '@almond/ui';

import { useRedirectBackToArtemis } from '~modules/integration';
import { Container } from '~modules/layout';
import { RecommendationMessage } from '~modules/ui';

import themedStyles from './styles';

export const InfectionUrgentCarePage: React.FC = () => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { backToArtemisLinkProps } = useRedirectBackToArtemis();

  return (
    <Container title={t('infectionOutcome.title')} id="infectionOutcome" size="XL">
      <RecommendationMessage
        subtitle={t('infectionOutcome.urgentCare.subtitle')}
        bodyText={
          <View style={styles.bodyWrapper}>
            <Text size="m">{t('infectionOutcome.urgentCare.body.part1')}</Text>
            <ul>
              {t('infectionOutcome.urgentCare.body.part2', { returnObjects: true }).map((text, i) => (
                <li key={i}>
                  <Text size="m">{text}</Text>
                </li>
              ))}
            </ul>
            <Text size="m">{t('infectionOutcome.urgentCare.body.part3')}</Text>
            <Text size="m">{t('infectionOutcome.urgentCare.body.part4')}</Text>
          </View>
        }
      />
      <View style={styles.ctaWrapper}>
        <Button {...backToArtemisLinkProps()} type="primary" testID="Submit">
          {t('backToDashboard')}
        </Button>
        <Button {...backToArtemisLinkProps('/messages')} type="secondary" testID="Secondary">
          {t('messageCareTeam')}
        </Button>
      </View>
    </Container>
  );
};
