import { useCallback } from 'react';

import { useRecoilValue } from 'recoil';

import { profilesApi } from '~modules/api';
import { profileAtom } from '~modules/state';

import { log, logAndCapture } from '../logger';

import type { FormValues } from '@almond/ui';
import type { InsuranceIdFormValues } from '~types';

const useAddInsuranceId = (): ((values: FormValues<InsuranceIdFormValues>) => Promise<void>) => {
  const profileState = useRecoilValue(profileAtom);

  return useCallback(
    async (values: FormValues<InsuranceIdFormValues>) => {
      if (!profileState.uuid) {
        return logAndCapture("`profileUuid` is not specified, so can't add the insurance id.");
      }

      if (!values.id) return log("The insurance id is not specified, so can't add the insurance id.");

      await profilesApi.addInsuranceId(profileState.uuid)({ insuranceMemberId: values.id });
    },
    [profileState.uuid]
  );
};

export default useAddInsuranceId;
