import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { useTheme } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { AuthButton } from '~modules/auth';
import { Container } from '~modules/layout';
import { useIsBaselineProduct } from '~modules/product';
import { useDetermineFirstMonthFree } from '~modules/profile';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { patientAtom } from '~modules/state';
import { MessageWithImageV2 } from '~modules/ui';

import { WrongUser } from './WrongUser';

import themedStyles from './styles';

import type { GET_STARTED_PAGE_NAME } from '~types';

export const GetStartedPage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync, isLoading } = useAsync();
  const { dispatch } = useRouteNavigation<typeof GET_STARTED_PAGE_NAME>();
  const [styles] = useTheme(themedStyles);
  const searchParams = useLocalSearchParams();
  const { patientUuid } = useRecoilValue(patientAtom);
  const isBaseline = useIsBaselineProduct();

  const isWrongUser = searchParams.patient_uuid && patientUuid && searchParams.patient_uuid !== patientUuid;

  useDetermineFirstMonthFree(false);

  const onIsAuthenticated = useCallback(() => {
    if (isWrongUser) {
      return;
    }

    const toCall = async (): Promise<void> => {
      await dispatch('signin');
    };

    doAsync(toCall);
  }, [doAsync, dispatch, isWrongUser]);

  if (isWrongUser) {
    return <WrongUser />;
  }

  return (
    <Container id="confirmed-getStarted" contentContainerStyle={styles.contentContainer}>
      <MessageWithImageV2
        image="relaxingWoman"
        imageDimensions={{ width: 634, height: 381 }}
        mobileImageDimensions={{ width: 253, height: 152 }}
        title={t(`confirmed.message.${isBaseline ? 'baselineTitle' : 'title'}`)}
        subtitle={t('confirmed.message.subtitle')}
        footer={
          <AuthButton onIsAuthenticated={onIsAuthenticated} isLoading={isLoading} testID="GetStarted">
            {t('confirmed.established.submitTitle')}
          </AuthButton>
        }
        isMessageOverImage
      />
    </Container>
  );
};
