import React from 'react';

import { Redirect, useLocalSearchParams } from 'expo-router';

export default () => {
  const { code, ...localSearchParams } = useLocalSearchParams();

  const searchParams = new URLSearchParams(localSearchParams);

  if (code) {
    searchParams.set('refer', code);
  }

  return <Redirect href={`/visit-reasons?${searchParams}`} />;
};
