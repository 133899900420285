import { useMemo } from 'react';

import { useTranslation } from '@almond/localization';
import { formatPriceInCents } from '@almond/utils';
import { useRecoilValue } from 'recoil';

import { creditCardAtom } from '~modules/state';

import type { NotificationStatusBarProps } from '~modules/ui';

export const useReferralCodeNotificationStatus = (): NotificationStatusBarProps | undefined => {
  const { t } = useTranslation();
  const creditCardState = useRecoilValue(creditCardAtom);

  return useMemo(() => {
    if (!creditCardState.friendReferralCode || creditCardState.friendReferralCode.state === 'init') {
      return;
    }

    let content = t(`promotionCodes.referral.${creditCardState.friendReferralCode.state}`);

    if (creditCardState.friendReferralCode.state === 'success') {
      const { amountOff } = creditCardState.friendReferralCode;

      if (amountOff != null) {
        content = t('promotionCodes.referral.success', { amountOff: formatPriceInCents(amountOff, true) });
      }
    }

    return {
      type: creditCardState.friendReferralCode.state === 'success' ? 'info' : 'error',
      content,
      isVisible: true,
    };
  }, [creditCardState.friendReferralCode, t]);
};
