import React from 'react';

import { Redirect, useLocalSearchParams } from 'expo-router';

import { withEnforceAuth } from '~modules/navigation';

export default withEnforceAuth(() => {
  const localSearchParams = useLocalSearchParams();
  const searchParams = new URLSearchParams(localSearchParams);

  searchParams.set('visit_reason', 'general_infection');

  return <Redirect href={`/recommendation?${searchParams}`} />;
});
