export const ALL_SET_PAGE_NAME = '/all-set' as const;
export const CONSENT_SIGNATURE_PAGE_NAME = '/consent-signature' as const;
export const CONFIRM_PURCHASE_PAGE_NAME = '/confirm-purchase' as const;
export const CREATE_PATIENT_PAGE_NAME = '/create-patient' as const;
export const GET_STARTED_PAGE_NAME = '/get-started' as const;
export const PATIENT_LOGIN_PAGE_NAME = '/login' as const;

export type AllSetParams = undefined;
export type ConsentSignatureParams = undefined;
export type CreatePatientParams = undefined;
export type ConfirmPurchaseParams = undefined;
export type GetStartedParams = undefined;
export type PatientLoginParams = undefined;
export type TypeformResponseParams = {
  outcome: 'npPaVisit' | 'reviewing' | 'urgentCare';
  typeform_type: 'infection_questionnaire' | 'baseline_intake';
};
