import React, { useCallback, useEffect, useState } from 'react';

import { ActivityIndicator } from '@almond/ui';
import { useFocusEffect, useLocalSearchParams } from 'expo-router';
import Head from 'expo-router/head';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { VirtualMembershipModal } from '~modules/integration';
import { integrationAtom, routingAlreadyInitializedAtom } from '~modules/state';
import { Background } from '~modules/ui';

import { withEnforceAuthWhenNecessary } from '../withEnforceAuthWhenNecessary';
import { RouteBehavior } from './RouteBehavior';

import type { RouteBehaviorProps } from './RouteBehavior';
import type { ComponentType, PropsWithChildren } from 'react';

type IntegrationProps = {
  disableIdleModal?: boolean;
  disableAbandonModal?: boolean;
};

type MetaProps = {
  title?: string;
};

export const withRouteBehavior = (
  Component: ComponentType<PropsWithChildren> = () => null,
  routeBehaviorProps: RouteBehaviorProps &
    IntegrationProps &
    MetaProps & { requireAuth?: boolean; loadingEle?: React.ReactNode } = {}
) => {
  const loadingEle = routeBehaviorProps.loadingEle || (
    <Background>
      <ActivityIndicator />
    </Background>
  );

  const Wrapped = () => {
    const [alreadyInitted, setAlreadyInitted] = useRecoilState(routingAlreadyInitializedAtom);
    // Persist the on-load value of the recoil state if it's false, because we're
    // immediately setting it to true in the useEffect()
    const [localAlreadyInitted, setLocalAlreadyInitted] = useState(alreadyInitted);
    const setIntegrationState = useSetRecoilState(integrationAtom);
    const searchParams = useLocalSearchParams();

    useEffect(() => {
      if (!alreadyInitted) {
        setAlreadyInitted(true);
      }
    }, [alreadyInitted, setAlreadyInitted]);

    useFocusEffect(
      useCallback(() => {
        setIntegrationState(prevState => ({
          ...prevState,
          isIdleModalDisabled: routeBehaviorProps.disableIdleModal,
          isAbandonModalDisabled: routeBehaviorProps.disableAbandonModal,
        }));

        return () => {
          // When the first page moves to the background, disable alreadyInittedRef
          setLocalAlreadyInitted(true);
        };
      }, [setIntegrationState])
    );

    // When applying an initial routing_config_id, it can take a React
    // render or two to propagate to all components. If it hasn't
    // propagated, we can get an error if a dispatch() is called in an
    // immediate call to dispatch() within a useEffect(), like we have
    // on VisitReasonsPage for new members. So don't render the page until
    // the routingConfigId value starts to be returned from
    // useLocalSearchParams()
    const routingConfigId = searchParams.routing_config_id;

    // TODO figure out a way to keep the same loading ele instance rendered
    // as we switch between each of these components rendering a loading ele.
    return (
      <>
        {routeBehaviorProps.title && (
          <Head>
            <title>{`${routeBehaviorProps.title} - Almond ObGyn`}</title>
          </Head>
        )}
        <RouteBehavior {...routeBehaviorProps} alreadyInitted={localAlreadyInitted}>
          {routingConfigId ? <Component /> : loadingEle}
        </RouteBehavior>
        <VirtualMembershipModal />
      </>
    );
  };

  Wrapped.displayName = `withRouteBehavior(${Component.displayName || Component.name || 'Component'})`;

  return withEnforceAuthWhenNecessary(Wrapped, routeBehaviorProps.requireAuth, loadingEle);
};
