import { useCallback } from 'react';

import { useFocusEffect } from 'expo-router';
import { useRecoilState } from 'recoil';

import { productsApi } from '~modules/api';
import { useIsBaselineProduct } from '~modules/product';
import { creditCardAtom } from '~modules/state';

export const useDetermineFirstMonthFree = (isResubscribe: boolean) => {
  const [creditCardState, setCreditCardState] = useRecoilState(creditCardAtom);
  const isBaseline = useIsBaselineProduct();

  useFocusEffect(
    useCallback(() => {
      if (isResubscribe || isBaseline) {
        if (creditCardState.promotionCode?.code === 'firstmonthfree') {
          setCreditCardState(prevState => ({
            ...prevState,
            promotionCode: undefined,
          }));
        }

        return;
      }

      // We explicitly check for undefined because undefined is the initial state of the app.
      // If a user removes a promo code, it'll get sent to null, which means the FIRSTMONTHFREE promo
      // code should not get applied.
      if (
        creditCardState.promotionCode === undefined ||
        creditCardState.promotionCode?.state === 'not-found' ||
        creditCardState.promotionCode?.state === 'not-applicable'
      ) {
        // Using .then() for simplicity - this is hardcoded to always resolve
        productsApi.checkPromotionCode('firstmonthfree').then(promotionCode => {
          setCreditCardState(prevState => ({ ...prevState, promotionCode }));
        });
      }
    }, [creditCardState.promotionCode, isBaseline, isResubscribe, setCreditCardState])
  );
};
