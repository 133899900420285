import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { CONTACT_US_PHONE_NUMBER, Link, Text, useTheme } from '@almond/ui';

import themedStyles from './styles';

import type { ViewProps } from 'react-native';

export type ContactUsProps = Pick<ViewProps, 'style'>;

export const ContactUs: React.FC<ContactUsProps> = props => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);

  return (
    <View style={props.style}>
      <Text size="xs" style={styles.text}>
        {t('contactUs.callOrTextUs')}
        <Link url={`+1${CONTACT_US_PHONE_NUMBER.replace(/[ ()-]/g, '')}`} urlType="phoneNumber" size="xs">
          {CONTACT_US_PHONE_NUMBER}
        </Link>
      </Text>
    </View>
  );
};
