import { useCallback } from 'react';

import { patientsApi } from '~modules/api';
import { HttpRequestError } from '~modules/errors';

import { log } from '../logger';

import type { ErrorTypeChoices } from '@almond/api-types';

export const useGetSubscription = () => {
  return useCallback(async () => {
    try {
      return await patientsApi.getSubscription();
    } catch (error) {
      const CAUGHT_ERROR_CODES = ['inactive_subscription', 'no_payment_method'] satisfies ErrorTypeChoices[];

      if (error instanceof HttpRequestError && error.errorCode && CAUGHT_ERROR_CODES.includes(error.errorCode as any)) {
        log('Subscription for patient is inactive or no payment method exists', { errorCode: error?.errorCode });

        return null;
      }

      throw error;
    }
  }, []);
};
