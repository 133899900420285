import React from 'react';
import { View } from 'react-native';

import { ScrollView, useTheme } from '@almond/ui';

import { Background } from '~modules/ui';

import ErrorMessage from '../ErrorMessage';

import themedStyles from './styles';

import type { ErrorMessageProps } from '../ErrorMessage';

const ErrorPage: React.FC<ErrorMessageProps> = props => {
  const [styles] = useTheme(themedStyles);

  // Jest doesn't support `aria-label`, so use `accessibilityLabel` for tests
  // `accessibilityLabel` shows a warning in the browser
  const label = Object.fromEntries([
    [process.env.NODE_ENV === 'test' ? 'accessibilityLabel' : 'aria-label', 'Oops, there was an error'],
  ]);

  // Recreate <Container>, avoid cyclic dependencies
  return (
    <Background {...label} testID="error">
      <ScrollView
        contentContainerStyle={[styles.scrollContainer, styles.horizontalPadding]}
        listenToNativeEvents
        testID="ContainerScrollView"
      >
        <View style={styles.verticalCenter}>
          <View style={styles.content}>
            <View style={styles.contentContainer}>
              <ErrorMessage onTryAgain={props.onTryAgain} error={props.error} isFull={false} />
            </View>
          </View>
        </View>
      </ScrollView>
    </Background>
  );
};

export default ErrorPage;
