import { StyleSheet } from 'react-native';

import { sizes, styleSheetFactory } from '@almond/ui';

export const visitReasonsThemedStyles = styleSheetFactory(() => ({
  visitReasonHeaderMobile: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const modalThemedStyles = styleSheetFactory(theme => ({
  container: {
    backgroundColor: theme.lightAccent,
    width: 640,
  },
  content: {
    maxWidth: 360,
    marginHorizontal: 'auto',
    alignItems: 'center',
    marginBottom: sizes.SPACING_L,
  },
  contentWithDivider: {
    borderTopColor: theme.border,
    borderTopWidth: StyleSheet.hairlineWidth,
    paddingTop: sizes.SPACING_L,
  },
  iconContainer: {
    width: 80,
    aspectRatio: 1,
    backgroundColor: theme.lightSecondaryBackground,
    borderRadius: sizes.SPACING_XXL,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: sizes.SPACING_M,
  },
  title: {
    color: theme.darkText,
  },
  titleContainer: {
    rowGap: sizes.SPACING_XS,
    marginBottom: sizes.SPACING_L,
  },
  center: {
    textAlign: 'center',
  },
  bodyWrapper: {
    gap: 14,
  },
  paragraph: {
    marginBottom: sizes.SPACING_M,
  },
  button: {
    marginHorizontal: 'auto',
  },
}));
