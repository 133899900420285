/* eslint-disable react/jsx-no-literals, max-statements */
import React, { Fragment } from 'react';

import { useTranslation } from '@almond/localization';
import { Link, Text } from '@almond/ui';
import { areArraysEquivalent } from '@almond/utils';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { appointmentParamsAtom } from '~modules/state';

import { useVisitReasons } from './useVisitReasons';

import type { VisitOutcome } from '../visitOutcomes';

const TEXT_SIZE = 'm';

export const useRecommendationBodyText = (visitOutcome: VisitOutcome | undefined) => {
  const { t } = useTranslation();
  const { isNewMemberRemote } = useRecoilValue(appointmentParamsAtom);
  const searchParams = useLocalSearchParams();
  const visitReason = searchParams.visit_reason;
  const { visitReasons } = useVisitReasons();

  if (!visitOutcome || !visitReason || !visitReasons) {
    return null;
  }

  const { isTelehealth, providerTypes } = visitOutcome;

  const visitReasonInSentence = visitOutcome.inSentence ?? visitReasons.find(vr => vr.code === visitReason)?.title;

  const bodyText: React.ReactNode[][] = [];

  const firstParagraph = [];

  const providerVariant = (() => {
    if (areArraysEquivalent(providerTypes, ['NP', 'PA'])) {
      return 'np';
    }

    if (areArraysEquivalent(providerTypes, ['HC'])) {
      return 'hc';
    }

    return 'md';
  })();

  if (visitReason === 'pcos_care') {
    // Hardcoding text here instead of putting in en.json. It would require an annoying amount of
    // workarounds to get translation working with these 2 embedded links, so I just did it this
    // way to be fast.
    // TODO (very low priority) move to en.json
    return (
      <>
        <Text size={TEXT_SIZE}>
          This will be a {visitOutcome.duration} minute video visit with one of our Women’s Health Physician’s
          Assistants or Nurse Practitioners. You will review your history, symptoms, discuss testing, goals and next
          steps.
        </Text>
        <Text>
          For more thorough PCOS care we offer{' '}
          <Link size="m" url="https://www.almondobgyn.com/the-pcos-membership">
            The PCOS Membership
          </Link>
          , a specialized, one-of-a-kind program that aims to understand the cause of your symptoms and brings together
          medical care and health coaching to help you achieve the best possible outcomes and improve your quality of
          life.
        </Text>
        <Text>
          You can{' '}
          <Link size="m" url="https://almondhealth.as.me/schedule.php?appointmentType=60391979">
            book an intro call
          </Link>{' '}
          to get started right away or ask your provider about this to learn more.
        </Text>
      </>
    );
  }

  if (providerTypes) {
    // Only show first sentence if specific provider
    if (isTelehealth === true) {
      firstParagraph.push(
        t('recommendation.copy.videoProvider', { context: providerVariant, visitReason: visitReasonInSentence })
      );
    } else if (isTelehealth === false) {
      firstParagraph.push(
        t('recommendation.copy.officeProvider', { context: providerVariant, visitReason: visitReasonInSentence })
      );
    } else if (isTelehealth === null) {
      firstParagraph.push(
        t('recommendation.copy.anyLocationProvider', { context: providerVariant, visitReason: visitReasonInSentence })
      );
    }

    firstParagraph.push(
      <Text fontStyle="bold" size={TEXT_SIZE}>
        {isTelehealth === false
          ? t('recommendation.copy.officeVisit', { duration: visitOutcome.duration })
          : t('recommendation.copy.videoVisit', {
              duration: visitOutcome.duration,
              context: isNewMemberRemote ? 'first' : undefined,
            })}
      </Text>
    );
  } else {
    let location: 'video' | 'office' | 'anyLocation' = isTelehealth ? 'video' : 'office';

    if (isTelehealth === null) {
      location = 'anyLocation';
    }

    firstParagraph.push(
      t(`recommendation.copy.${location}NoProvider`, {
        context: isNewMemberRemote ? 'first' : undefined,
        visitReason: visitReasonInSentence,
        duration: visitOutcome.duration,
      })
    );
  }

  bodyText.push(firstParagraph);
  if (areArraysEquivalent(providerTypes, ['NP', 'PA'])) {
    bodyText.push([t('recommendation.copy.summary')]);
  }

  return bodyText.map((paragraph, i) => (
    <Text key={i} size={TEXT_SIZE}>
      {paragraph.map((sentence, ii) => (
        <Fragment key={ii}>
          {sentence}
          {' ' /* space between sentences */}
        </Fragment>
      ))}
    </Text>
  ));
};
