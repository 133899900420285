import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  modalContainer: {
    width: 640,
    minHeight: 440,
    backgroundColor: theme.lightSecondaryBackground,
    maxWidth: '95%',
  },

  modalContainerMobile: {
    height: '100%',
    maxWidth: '100%',
  },

  selfCenter: {
    alignSelf: 'center',
  },

  subtitle: {
    marginTop: sizes.SPACING_S,
    width: '80%',
  },

  centerText: {
    textAlign: 'center',
  },
}));
