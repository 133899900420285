import React, { useEffect } from 'react';

import { Button } from '@almond/ui';
import { useAuth0 } from '@auth0/auth0-react';
import { useIsFocused } from '@react-navigation/native';
import { useRecoilValue } from 'recoil';

import { userAtom } from '~modules/state';

import { useLoginWithRedirect } from '../../hooks';
import { AUTH_STATES, useAuthState } from '../AuthStateProvider/AuthStateContext';

import type { UserDetail } from '@almond/api-types';
import type { RedirectLoginOptions } from '~types';

export type AuthButtonProps = React.PropsWithChildren<{
  onIsAuthenticated?: (user?: UserDetail) => void;
  options?: RedirectLoginOptions;
  isLoading?: boolean;
  testID?: string;
}>;

const AuthButton: React.FC<AuthButtonProps> = props => {
  const { onIsAuthenticated } = props;
  const loginWithRedirect = useLoginWithRedirect();
  const { isLoading, isAuthenticated } = useAuth0();
  const authState = useAuthState();
  const userState = useRecoilValue(userAtom);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isFocused || isLoading || !isAuthenticated || !onIsAuthenticated) return;

    if (authState === AUTH_STATES.loading) return;

    onIsAuthenticated(userState.user);
  }, [isAuthenticated, isLoading, onIsAuthenticated, userState.user, authState, isFocused]);

  return (
    <Button
      isLoading={props.isLoading || isLoading}
      isDisabled={props.isLoading || isLoading}
      onPress={() => loginWithRedirect(props.options)}
      testID={props.testID}
    >
      {props.children}
    </Button>
  );
};

export default AuthButton;
