import React, { useCallback, useEffect } from 'react';

import { useTranslation } from '@almond/localization';
import { Text, useTheme } from '@almond/ui';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Stars } from '~assets';
import { ContainerWithSidebar } from '~modules/layout';
import { isBaselineProduct, isVirtualMembershipProduct, useStripeProduct } from '~modules/product';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { appointmentParamsAtom, creditCardAtom, patientProgressAtom } from '~modules/state';
import { NotificationStatusBar } from '~modules/ui';

import { useDetermineFirstMonthFree } from '../../hooks';
import { Baseline } from './Baseline';
import { Membership } from './Membership';
import { SidebarContent } from './SidebarContent';
import { usePricingExplanationTitle } from './usePricingExplanationTitle';

import themedStyles from './styles';

import type { PRICING_EXPLANATION_PAGE_NAME } from '~types';

export const PricingExplanationPage: React.FC = () => {
  const { dispatch } = useRouteNavigation<typeof PRICING_EXPLANATION_PAGE_NAME>();
  const { doAsync, isLoading } = useAsync();
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { subscriptionActive } = useRecoilValue(patientProgressAtom);
  const { isNewMemberRemote } = useRecoilValue(appointmentParamsAtom);
  const isResubscribe = !subscriptionActive && !isNewMemberRemote;
  const setCreditCardState = useSetRecoilState(creditCardAtom);
  const { product } = useStripeProduct();
  const isBaseline = isBaselineProduct(product);
  const isVirtualMembership = isVirtualMembershipProduct(product);
  const { title, subtitle } = usePricingExplanationTitle(product);

  const variant = (() => {
    if (isBaseline) return 'baseline';
    if (isVirtualMembership) return 'virtualMembership';

    return 'gynecologyMembership';
  })();

  useEffect(() => {
    if (isResubscribe) {
      setCreditCardState(prev => ({ ...prev, promotionCode: undefined }));
    }
  }, [isResubscribe, setCreditCardState]);

  // If a user accidentally clears FIRSTMONTHFREE code or clicks a link with an invalid code,
  // they can go back to the PricingExplanation page and the code will be reapplied
  useDetermineFirstMonthFree(isResubscribe);

  const handleSubmit = useCallback(() => {
    const toCall = () => dispatch('submit');

    doAsync(toCall);
  }, [doAsync, dispatch]);

  const content = (() => {
    if (variant === 'baseline') {
      return <Baseline onSubmitPress={handleSubmit} isLoading={isLoading} />;
    }

    return <Membership onSubmitPress={handleSubmit} isLoading={isLoading} variant={variant} />;
  })();

  return (
    <ContainerWithSidebar
      id={`pricingExplanation.${variant}`}
      statusBar={
        isResubscribe && <NotificationStatusBar type="info" content={t(`pricingExplanation.resubscribeBanner`)} />
      }
      content={content}
      sidebarContent={!isBaseline && <SidebarContent />}
      title={
        <Text
          testID={`pricingExplanation.${variant}:${title}`}
          role="heading"
          aria-level={1}
          fontStyle="medium"
          size="xxxxl"
          style={styles.centerText}
        >
          <Stars style={styles.stars} />
          {title}
        </Text>
      }
      subtitle={
        <Text size="l" style={styles.subtitle}>
          {subtitle}
        </Text>
      }
    />
  );
};
