import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Button, Text, useTheme } from '@almond/ui';

import { LocalImage } from '~modules/ui';

import themedStyles from './styles';

import type { StyleProp } from 'react-native';

class ViewStyle {}

export type ErrorMessageProps = {
  onTryAgain?: () => void;
  error?: Error;
  title?: string;
  isFull?: boolean;
  style?: StyleProp<ViewStyle>;
  retryText?: string;
};

const ErrorMessage: React.FC<ErrorMessageProps> = props => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { isFull = true, style } = props;

  const text =
    process.env.EXPO_PUBLIC_ENV === 'prod' || !props.error
      ? `Let's try that again! Let us know if the problem is persisting.`
      : props.error.message;

  const helpscoutAvailable = typeof window !== 'undefined' && !!(window as any).Beacon?.('info');
  const openHelpscout = () => {
    if (typeof window !== 'undefined') {
      (window as any).Beacon?.('open');
    }
  };

  return (
    <View
      style={[styles.container, isFull ? styles.containerFull : styles.containerPartial, style]}
      testID="ErrorMessage"
    >
      <LocalImage source="error" style={styles.image} resizeMode="contain" alt="" />
      <Text fontStyle="bold" size="xl" style={styles.title}>
        {props.title ?? t('appError.title')}
      </Text>
      <Text fontStyle="bold" size="m" style={styles.subtitle}>
        {text}
      </Text>
      <View style={styles.buttons}>
        {props.onTryAgain && (
          <Button onPress={props.onTryAgain} style={styles.button} fixedWidth={false}>
            {props.retryText ?? t('appError.retry')}
          </Button>
        )}
        {helpscoutAvailable && (
          <Button onPress={openHelpscout} style={styles.button} type="secondary" fixedWidth={false}>
            {t('appError.getHelp')}
          </Button>
        )}
      </View>
    </View>
  );
};

export default ErrorMessage;
