export type SendEmailInParams = {
  patient_uuid?: string;
  appointment_uuid?: string;
  booking_uuid?: string;
  profile_uuid?: string;
  email?: string;
  artemis_redirect_url?: string;
  product?: string;
  visit_reason?: string;
};
