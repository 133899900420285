import React from 'react';
import { Image, useWindowDimensions, View } from 'react-native';

import { useBrowserTypeMap, useIsReducedMotionEnabled, useTheme } from '@almond/ui';

import animation from './animation.gif';

import themedStyles from './styles';

const WIDTH_COEFFICIENT = 3.5;
const HEIGHT_COEFFICIENT = 2.16;

export const ConfettiBurstsDecoration = () => {
  const [styles] = useTheme(themedStyles);
  const dimensions = useWindowDimensions();
  const { isMobile } = useBrowserTypeMap();
  const imageWidthMobile = dimensions.height / WIDTH_COEFFICIENT;
  const imageWidthDesktop = dimensions.height / WIDTH_COEFFICIENT;
  const imageMarginLeftMobile = (dimensions.width - imageWidthMobile) / 2;
  const imageMarginLeftDesktop = dimensions.width / 4;
  const width = isMobile ? imageWidthMobile : imageWidthDesktop;
  const marginLeft = isMobile ? imageMarginLeftMobile : imageMarginLeftDesktop;
  const height = width * HEIGHT_COEFFICIENT;

  return (
    <View style={[{ width, marginLeft }, styles.container]}>
      {useIsReducedMotionEnabled() ? null : <Image source={animation} style={[styles.animation, { height }]} />}
    </View>
  );
};
