import React, { useCallback } from 'react';
import { View } from 'react-native';

import { useBrowserTypeMap, useTheme } from '@almond/ui';

import { patientsApi } from '~modules/api';
import { NavigationBar, useRedirectBackToArtemis } from '~modules/integration';
import { useNavigationBar } from '~modules/patient';
import { baselineMessage, TypeformWidget } from '~modules/typeform';
import { Background, ContactUs } from '~modules/ui';

import themedStyles from './styles';

const BASELINE_INTAKE_TYPEFORM_ID = 'pdwSdBcw';
const IS_PROD = process.env.EXPO_PUBLIC_ENV === 'prod';

export const BaselineIntakePage: React.FC = () => {
  const { isLoading: isLoadingNavigationBar, bannerText } = useNavigationBar();
  const [styles] = useTheme(themedStyles);
  const { redirectBackToArtemis } = useRedirectBackToArtemis();
  const { isMobile } = useBrowserTypeMap();

  const onSubmit = useCallback(async () => {
    await patientsApi.sendSystemMessage('baseline_intake_completion');
  }, []);

  const onMessage = useCallback(() => {
    redirectBackToArtemis();
  }, [redirectBackToArtemis]);

  return (
    <Background testID="baselineIntakePage" noWave>
      <NavigationBar isLoading={isLoadingNavigationBar} bannerText={bannerText} />
      <TypeformWidget
        isProd={IS_PROD}
        id={BASELINE_INTAKE_TYPEFORM_ID}
        onMessage={onMessage}
        onSubmit={onSubmit}
        schema={baselineMessage}
      />
      <View style={[styles.footer, isMobile && styles.footerContentMobile]}>
        <ContactUs style={styles.contactUs} />
      </View>
    </Background>
  );
};
