import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { ActivityIndicator, Button, List, Text, useTheme } from '@almond/ui';

import { ErrorMessage } from '~modules/errors';
import { useStripeProduct } from '~modules/product';

import themedStyles from './styles';

export type BaselineProps = {
  onSubmitPress: () => void;
  isLoading: boolean;
};

export const Baseline: React.FC<BaselineProps> = props => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { product, isLoading, error, mutate } = useStripeProduct();

  if (isLoading) {
    return <ActivityIndicator />;
  }

  if (error || !product) {
    return <ErrorMessage error={error} onTryAgain={() => mutate()} isFull={false} style={styles.errorMessage} />;
  }

  return (
    <View style={styles.container}>
      <View style={styles.wideBanner}>
        <Text size="xl" fontStyle="bold" style={styles.sectionTitle}>
          {t('pricingExplanation.baseline.subscriptionItemsTitle')}
        </Text>
        <List.UnorderedList style={styles.sectionDescription}>
          {t('pricingExplanation.baseline.subscriptionItems', { returnObjects: true }).map((item, index) => (
            <List.ListItem key={index} style={styles.baselineSectionItem}>
              <Text size="l">{item}</Text>
            </List.ListItem>
          ))}
        </List.UnorderedList>
        <Button
          type="primary"
          style={styles.fixedWidthButton}
          onPress={props.onSubmitPress}
          isLoading={props.isLoading}
          isDisabled={props.isLoading}
          fixedWidth={true}
          size="m"
          testID="Submit"
        >
          {t('pricingExplanation.baseline.submit')}
        </Button>
      </View>
    </View>
  );
};
