import { useSetRecoilState } from 'recoil';

import { patientsApi } from '~modules/api';
import { patientProgressAtom } from '~modules/state';

import { usePurchase } from './usePurchase';

export const useCreateInvoice = () => {
  const setPatientProgressState = useSetRecoilState(patientProgressAtom);

  return usePurchase({
    createPayment: async params => {
      if (!params.price) {
        throw new Error('Price is required');
      }

      const invoice = await patientsApi.createInvoice({
        ...params,
        price: params.price,
      });

      return {
        stripeId: invoice.stripeInvoiceId,
        clientSecret: invoice.clientSecret,
        status: invoice.status,
        type: 'payment',
      };
    },
    onSuccess: () => {
      setPatientProgressState(prevState => ({ ...prevState, invoice: true }));
    },
  });
};
