import { styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  buttonContainer: {
    maxWidth: '100%',
    width: 160,
  },
  closeTitle: {
    color: theme.lightText,
  },
  modalImage: {
    position: 'absolute',
    resizeMode: 'contain',
    left: '65%',
    right: '-15%',
    top: 0,
    bottom: 0,
  },
  modalImageMobile: {
    opacity: 0.5,
    left: '25%',
    right: '-30%',
  },
  image: {
    width: '100%',
    height: '100%',
  },
}));
