import { useRecoilValue } from 'recoil';

import { patientProgressAtom } from '~modules/state';

import { isBaselineProduct } from '../services';
import { useStripeProduct } from './useStripeProduct';

export const useStripeMembership = () => {
  const { product } = useStripeProduct();
  const { subscription, subscriptionActive } = useRecoilValue(patientProgressAtom);

  if (subscriptionActive && subscription?.membershipType) {
    return subscription.membershipType;
  }

  const key = product?.almondProductKey;

  if (!key || isBaselineProduct(key)) {
    return null;
  }

  return key;
};
