import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

const size = 32;

export default styleSheetFactory(theme => ({
  userAvatarMenu: {
    backgroundColor: theme.lightAccent,
    paddingVertical: sizes.SPACING_S,
    borderRadius: size / 2,
    width: size,
    height: size,
  },
  initials: {
    color: theme.accent,
    width: '100%',
    textAlign: 'center',
    fontSize: size * 0.4,
  },

  modalBackdrop: {
    backgroundColor: 'transparent',
  },

  menu: {
    ...getShadow(),
    backgroundColor: theme.background,
    width: 160,
    position: 'absolute',
    top: 52,
    end: sizes.SPACING_M,
    padding: 12,
    rowGap: 10,
    borderRadius: sizes.SPACING_XS,
  },

  menuText: {
    color: theme.darkText,
  },

  button: {
    marginEnd: sizes.SPACING_M,
  },
}));
