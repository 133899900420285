import React, { useMemo } from 'react';

import { useTheme } from '@almond/ui';
import { Elements as StripeElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useRecoilValue } from 'recoil';

import env from '~env';
import { ErrorMessage } from '~modules/errors';
import { useStripeProduct } from '~modules/product';
import { creditCardAtom } from '~modules/state';

import { useCalculatePrice } from '../../hooks';

import type { StripeElementsOptions } from '@stripe/stripe-js';

const stripePromise = loadStripe(env.STRIPE_API_KEY);
const boxShadow = '0px 4px 5px 0px rgba(0,0,0,0.2)';

export const Elements = (props: React.PropsWithChildren) => {
  const { children } = props;
  const creditCardState = useRecoilValue(creditCardAtom);
  const { product, error: productError, mutate: retryProduct } = useStripeProduct();
  const [theme] = useTheme();
  const {
    discountPrice: amount,
    error: errorCalculatePrice,
    retry: retryCalculatePrice,
  } = useCalculatePrice(product, creditCardState);

  const mode = useMemo(() => {
    if (!product) return 'subscription';

    if (amount === 0) return 'setup';

    return product.price.type === 'one_time' ? 'payment' : 'subscription';
  }, [product, amount]);

  const options: StripeElementsOptions = useMemo(
    () =>
      ({
        mode,
        amount: amount ?? 0,
        currency: 'usd',
        paymentMethodCreation: 'manual',
        appearance: {
          theme: 'flat',
          variables: {
            colorPrimary: theme.primary,
            colorBackground: theme.background,
            colorText: theme.text,
            colorSuccess: theme.info,
            colorDanger: theme.error,
            colorWarning: theme.lightError,
          },
          labels: 'floating',
          rules: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '.Input': {
              boxShadow,
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '.Tab': {
              boxShadow,
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '.TermsText': {
              // Hiding default TermsText to display a custom one
              fontSize: '0',
            },
          },
        },
        paymentMethodTypes: ['card'],
      }) as const,
    [amount, mode, theme.background, theme.error, theme.info, theme.lightError, theme.primary, theme.text]
  );

  if (productError) {
    return <ErrorMessage error={productError} onTryAgain={retryProduct} />;
  }

  if (errorCalculatePrice) {
    return <ErrorMessage error={errorCalculatePrice} onTryAgain={retryCalculatePrice} />;
  }

  return (
    <StripeElements stripe={stripePromise} options={options}>
      {children}
    </StripeElements>
  );
};
