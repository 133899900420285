import { useTrackAnalyticsEvent } from '@almond/analytics';
import { urlSearchParamsToObject, useEvent } from '@almond/utils';
import { useRouter } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { patientAtom } from '~modules/state';

import { useProcessPath } from './useProcessPath';

import type { BaseParams, BaseParamsWithoutRouting, RoutingConfig, StackParamList } from '~types';

const useProcessInitialUrl = () => {
  const processPath = useProcessPath();
  const router = useRouter();
  const trackAnalyticsEvent = useTrackAnalyticsEvent();
  const { isNewMember } = useRecoilValue(patientAtom);

  return useEvent(
    (
      allowDirectRouting: boolean,
      routingConfig: RoutingConfig<StackParamList> | null,
      forceSearchParams: BaseParamsWithoutRouting | undefined
    ) => {
      const parsedUrl = new URL(window.location.href);

      const { searchParams } = parsedUrl;

      // Allow each page to specify required searchParams
      Object.entries(forceSearchParams || {}).forEach(([key, value]) => {
        searchParams.set(key, value);
      });

      trackAnalyticsEvent('is_new_member', { isNewMember });

      if (allowDirectRouting && routingConfig) {
        const routingConfigIdToSet = routingConfig.id;

        searchParams.set('routing_config_id', routingConfigIdToSet);

        // Attach to passed routeConfig at the correct route
        // If router.setParams() is called synchronously during the first render
        // of a route, Expo Router throws this error:
        // > Attempted to navigate before mounting the Root Layout component.
        // > Ensure the Root Layout component is rendering a Slot, or other
        // > navigator on the first render.
        // Delay setting param solves this issue
        requestAnimationFrame(() => {
          // `routeToConfig()` just figures out which page in the config should be routed
          // to, and sets the routing_config_id. Since we know we're staying on the current
          // page, we just need to persist the routing_config_id in the URL to hook into
          // the routing config for upcoming navigations.
          router.setParams<BaseParams>(urlSearchParamsToObject(searchParams) as any);
        });

        return;
      }

      // Either a) no routing config set, so reset to beginning of flow, or
      // b) there was a routing config in the URL that didn't match the config
      // set, which happens when refreshing the page on a page that can't
      // handle a refresh within a specific config (i.e. Scheduling deep into a
      // curated flow), so bail and force the user to go to beginning of flow.
      processPath(isNewMember, searchParams);
    }
  );
};

export default useProcessInitialUrl;
